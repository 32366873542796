import '../css/wallpaper.css';
import React from 'react';
import WALL from '../img/wall.webp';
import G3 from '../img/pets.jpg';


class Wallpaper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth : window.innerWidth,
      windowHeight : window.innerHeight +1
    }
  }
  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight +1 });
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render (){
    return (
      <div style={{ backgroundImage: window.matchMedia("(max-width: 1000px)").matches ? "url(" + G3 + ")" : "url(" + WALL + ")" , height:window.innerHeight , width:window.innerWidth}} className='wallpaper-wrapper'/>
    );
  }
}

export default Wallpaper;
