import {connect} from 'react-redux';
import React from 'react';
import { aboutBigTextYoffsetAction } from '../../actions/main';

class AboutBigText extends React.Component {
  constructor(props) {
   super(props);
   this.wrapper = React.createRef();
   this.state = {
     windowWidth: window.innerWidth,
     windowHeight: window.innerHeight
   };
 }
 componentDidMount() {
   window.addEventListener('resize', this.updateDimensions);
   this.props.dispatch(aboutBigTextYoffsetAction( this.wrapper ? this.wrapper.current.offsetTop : null ));
 }
 updateDimensions = () => {
   this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
   this.props.dispatch(aboutBigTextYoffsetAction( this.wrapper ? this.wrapper.current.offsetTop : null ));
 }
 componentWillUnmount() {
   window.removeEventListener('resize', this.updateDimensions);
 }
  render(){
    const { showAboutBigTextParam , engSrbParam } = this.props;
    console.log(showAboutBigTextParam , '<-------')
    return (
      <div ref={this.wrapper} style={{opacity: showAboutBigTextParam ? 1 : 0 , transition: 'all 1s ease-out' , width: window.matchMedia("(max-width: 800px)").matches ? window.innerWidth - window.innerWidth/10 : window.innerWidth - window.innerWidth / 3 , marginTop:'5%' , marginBottom:'5%' }}>
        <div className='about-we'>
          <p className='about-we-wrapper'>O</p>
          <p className='about-we-wrapper'>{engSrbParam === 'eng' ? 'U' : 'V' }</p>
          <p className='about-we-wrapper'>{engSrbParam === 'eng' ? 'R' : 'O' }</p>
        </div>
        <p className='about-content'>
        {
          engSrbParam ==='eng' ? `story goes like this. At first we thought of buying a Bengal cat as a pet, in the next moment we fall in love
         with this breed and realize that it is a total unknown and that the vast majority have never seen, let alone heard of, these beautiful cats
         and we decide to buy more of them and thus set sail into the world of breeding. We are on the Belgrade-Kosovo route. We tried to choose the
         best quality specimens and brought them from various countries. We brought a male from Portugal, one female from Poland and 3 females from Russia.
         All our cats were tested for genetic diseases in America at the prestigious UC DAVIS clinic, which is the most recognized in the world for tests
         of this type. In addition, we regularly perform HCM-ultrasound of the heart on all our cats. In addition to health, we took care of beauty - cats
         have the best rosettes, the most beautiful color and every cat has glitter (shiny fur). We are a registered WCF (World Cat Federation) cattery in
         the Le PETit club. All cats live with us, they are members of our family.`
         :
         `je naša priča. Prvo smo zamislili da kupimo bengalsku mačku za ljubimca,u sledećem trenutku se zaljubljujemo u ovu rasu i shvatamo da je ona
         totalna nepoznanica i da ogromna većina nikada nije videla,a kamoli čula za ove prelepe mačke i odlučijemo se da ih kupimo više i time uplovimo
         u svet uzgoja. Mi smo na relaciji Beograd-Kosovo. Potrudili smo se da izaberemo najkvalitetnije primerke i dovedemo ih iz raznih zemalja. Mužijaka
         smo doveli iz Portugala,jednu ženku iz Poljske i 3 ženke iz Rusije. Svim nasim mačkama smo radili testove na gentske bolesti u Americi na prestižnoj
         klinici UC DAVIS koja je za testiranja tog tipa najpriznatija u svetu. Pored toga redovno svim nasim mačkama radimo HCM-ultrazvuk srca.
         Osim zdravlja vodili smo racuna o lepoti- nase mačke imaju najbolje rozete,najlepsu boju i svaka mačka ima glitter (sjajno krzno).
         Mi smo registrovana WCF (World Cat Federation) odgajivačnica u klubu Le PETit. Sve mačke žive sa nama one su članovi naše porodice.`

        }
        </p>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const showAboutBigTextParam = state.showAboutBigTextParam;
  const engSrbParam = state.engSrbParam;


  return {
    showAboutBigTextParam,
    engSrbParam
  }
}

export default connect(mapStateToProps)(AboutBigText);
