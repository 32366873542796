import './App.css';
import Home from './components/Home';
import About from './components/about/About';
import Gallery from './components/gallery/Gallery';
import VideoGallery from './components/videoGallery/VideoGallery';
import Contact from './components/Contact';

import {connect} from 'react-redux';
import React from 'react';
import { showAboutBigTextAction , showAboutThreeContainersAction , showGalleryHeadlineAction ,
  asyncMarginTopAction , showGalleryImagesAction , showAboutTeamAction , videoGalleryYoffsetAction , showVideoGalleryAction} from './actions/main';

class App extends React.Component {
  constructor(props) {
   super(props);
   this.state = {
     windowWidth: window.innerWidth,
     windowHeight: window.innerHeight
   }
 }
 componentDidMount() {
   window.addEventListener('resize', this.updateDimensions);
 }
 updateDimensions = () => {
   this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
 }
 componentWillUnmount() {
   window.removeEventListener('resize', this.updateDimensions);
 }
  render(){
    const { aboutBigTextYoffsetParam , aboutThreeContainersYoffsetParam , galleryHeadlineYoffsetParam , asyncMarginTopParam , aboutTeamYoffsetParam , videoGalleryYoffsetParam } = this.props;
    window.onscroll = () => {
      if(window.scrollY > (aboutBigTextYoffsetParam - window.innerHeight/2)) {
        this.props.dispatch(showAboutBigTextAction(true));
      }
      if(window.scrollY > (aboutThreeContainersYoffsetParam - window.innerHeight /2)) {
        this.props.dispatch(showAboutThreeContainersAction(true));
      }
      if(window.scrollY > (aboutTeamYoffsetParam - window.innerHeight /2)) {
        this.props.dispatch(showAboutTeamAction(true));
      }
      if(window.scrollY > (galleryHeadlineYoffsetParam - window.innerHeight)) {
        this.props.dispatch(showGalleryHeadlineAction(true));
      }
      if(window.scrollY > (galleryHeadlineYoffsetParam - window.innerHeight/1.4)) {
        this.props.dispatch(showGalleryImagesAction(true));
      }
      if(window.scrollY > (galleryHeadlineYoffsetParam + window.innerHeight/10)) {
        this.props.dispatch(asyncMarginTopAction(window.scrollY - galleryHeadlineYoffsetParam + window.innerHeight/25 - window.innerHeight/10));
      }
      else{
        this.props.dispatch(asyncMarginTopAction('5%'));
      }
      if(window.scrollY > (videoGalleryYoffsetParam - window.innerHeight)) {
        this.props.dispatch(showVideoGalleryAction(true));
      }
    }
   return (
     <div className="App">
       <Home/>
       <About/>
       <Gallery/>
       <VideoGallery/>
       <Contact/>
     </div>
   );
}
}
const mapStateToProps = (state) => {
  const aboutBigTextYoffsetParam = state.aboutBigTextYoffsetParam;
  const aboutThreeContainersYoffsetParam = state.aboutThreeContainersYoffsetParam;
  const galleryHeadlineYoffsetParam = state.galleryHeadlineYoffsetParam;
  const asyncMarginTopParam = state.asyncMarginTopParam;
  const aboutTeamYoffsetParam = state.aboutTeamYoffsetParam;
  const videoGalleryYoffsetParam = state.videoGalleryYoffsetParam;


  return {
    aboutBigTextYoffsetParam,
    aboutThreeContainersYoffsetParam,
    galleryHeadlineYoffsetParam,
    asyncMarginTopParam,
    aboutTeamYoffsetParam,
    videoGalleryYoffsetParam
  }
}
export default connect(mapStateToProps)(App);
