import '../../css/gallery.css'
import {connect} from 'react-redux';
import React from 'react';

class GalleryBigPicture extends React.Component {
  constructor(props) {
   super(props);
   this.state = {
     windowWidth: window.innerWidth,
     windowHeight: window.innerHeight,

   }
 }
 updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  }
 componentDidMount() {
   window.addEventListener('resize', this.updateDimensions);
 }
 componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
 render(){
   const {    galleryPickedImageParam , showGalleryImagesParam} = this.props;

   return (
     <div style={{flex:1 }}>
       <div className='gallery-big-picture-wrapper' style={{opacity:showGalleryImagesParam ? 1 : 0 ,backgroundColor:'black' , transition: 'all 1s ease-out',  backgroundImage: "url(" + galleryPickedImageParam + ")"   , marginTop:  '5%' , width: '90%' , height:window.innerHeight - window.innerHeight/20  }}>
       </div>


     </div>
   );
 }

}

const mapStateToProps = (state) => {
  const galleryPickedImageParam = state.galleryPickedImageParam;
  const showGalleryImagesParam = state.showGalleryImagesParam;

  return {
    galleryPickedImageParam,
    showGalleryImagesParam
  }
}
export default connect(mapStateToProps)(GalleryBigPicture);
