import {connect} from 'react-redux';
import React from 'react';
import { aboutThreeContainersYoffsetAction } from '../../actions/main';

class AboutThreeContainers extends React.Component {
  constructor(props) {
   super(props);
   this.wrapper = React.createRef();
   this.state = {
     windowWidth: window.innerWidth,
     windowHeight: window.innerHeight
   };
 }
 componentDidMount() {
   window.addEventListener('resize', this.updateDimensions);
   this.props.dispatch(aboutThreeContainersYoffsetAction( this.wrapper ? this.wrapper.current.offsetTop : null ));
 }
 updateDimensions = () => {
   this.props.dispatch(aboutThreeContainersYoffsetAction( this.wrapper ? this.wrapper.current.offsetTop : null ));
 }
 componentWillUnmount() {
   window.removeEventListener('resize', this.updateDimensions);
 }
  render(){
    const { showAboutThreeContainersParam , engSrbParam } = this.props;
    console.log(showAboutThreeContainersParam , '->>>>')
    return (
      <div ref={this.wrapper}  style={{display:'flex', width:window.innerWidth - window.innerWidth / 3   }} className='about-image-text-wrapper'>
        <div className={showAboutThreeContainersParam ? 'about-left-side-wrapper' : 'about-left-side-wrapper-before'}>
          <div className='about-textimage-common-style about-left-side-image'/>
          <div className='about-textimage-content'>
            <div style={{ fontSize:'25px' , margin:'5%' }}>{ engSrbParam === 'eng' ? 'Cattery' : 'Odgajivačnica'}</div>
            <div style={{ fontSize:window.matchMedia("(min-width: 1000px)").matches ? '15px' : '13px' , margin:'5%'  , textAlign: 'justify', wordWrap: 'break-word'}}>
            {
              engSrbParam ==='eng' ?
              `In addition to beauty, we also think about health, because a healthy cat can live up to 20 years.
              Since this breed is prone to genetic diseases, we tested all our cats in America at UC Davis (the most recognized clinic in the world)
              to make sure they are healthy.`
              :
              `Osim lepote mislimo i na zdravlje jer zdrava macka moze da zivi i do 20god. Posto je ova rasa sklona genetskim bolestima mi smo sve
              nase macke testirali u Americi na UC Davis (najpriznatija klinika u svetu) kako bi smo bili sigurni su zdrave.`
            }
            </div>
          </div>
        </div>
        <div className={showAboutThreeContainersParam ? 'about-center-side-wrapper' : 'about-center-side-wrapper-before'}>
          <div className='about-textimage-common-style about-center-side-image'/>
          <div className='about-textimage-content'>
            <div style={{ fontSize:'25px' , margin:'5%' }}>WCF</div>
            <div style={{ fontSize:window.matchMedia("(min-width: 1000px)").matches ? '15px' : '14px' , margin:'5%'  , textAlign: 'justify', wordWrap: 'break-word'}}>
            {
              engSrbParam === 'eng' ?
              `We are registrated at the World Cat Federation (WCF), international association of cat clubs. The WCF allows the breeders of affiliated clubs
              to register their kennel names internationally.`
              :
              `Registrovani smo u Svetskoj federaciji macaka(WFC), medjunarodnom udruzenju macijih klubova.WCF dozvoljava odgajivacima povezanih klubova da registruju
              imena svojih odgajivacnica na medjunarodnom nivou.`
            }
            </div>
          </div>
        </div>
        <div className={showAboutThreeContainersParam ? 'about-right-side-wrapper' : 'about-right-side-wrapper-before'}>
          <div className='about-textimage-common-style about-right-side-image'/>
          <div className='about-textimage-content'>
            <div style={{ fontSize:'25px' , margin:'5%' }}>{ engSrbParam === 'eng' ? 'Pets' : 'Mačka' }</div>
            <div style={{ fontSize: window.matchMedia("(min-width: 1000px)").matches ? '15px' : '14px' , margin:'5%'  , textAlign: 'justify', wordWrap: 'break-word'}}>
            {
              engSrbParam === 'eng' ?
              `Our beautiful cats are imported from the best kennels from Russia, Portugal and Poland. Glitter, contrast and regular rosettes -
              that's what makes us stand out.`
              :
              `Uvezli smo najlepse mace iz najboljih odgajivacnica iz Rusije,Portugala i Poljske. Glitter,kontrast i pravilne rozete-to je ono po cemu smo mi preponatljivi.`
            }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const showAboutThreeContainersParam = state.showAboutThreeContainersParam;
  const engSrbParam = state.engSrbParam;

  return {
    showAboutThreeContainersParam,
    engSrbParam
  }
}

export default connect(mapStateToProps)(AboutThreeContainers);
