import {
  TEST_ACTION,
  ABOUTBIGTEXTYOFFSET_ACTION,
  SHOWABOUTBIGTEXT_ACTION,
  ABOUTTHREECONTAINERSYOFFSET_ACTION,
  SHOWABOUTTHREECONTAINERS_ACTION,
  GALLERYHEADLINEYOFFSET_ACTION,
  SHOWAGALLERYHEADLINE_ACTION,
  ASYNCMARGINTOP_ACTION,
  GALLERYPICKEDIMAGE_ACTION,
  SHOWGALLERYIMAGES_ACTION,
  ABOUTTEAMYOFFSET_ACTION,
  SHOWABOUTTEAM_ACTION,
  VIDEOGALLERYYOFFSET_ACTION,
  SHOWVIDEOGALLERY_ACTION,
  CONTACTYOFFSET_ACTION,
  OPENBIGPICTURE_ACTION,
  ENGSRB_ACTION
} from '../constants';

const initialState = {
  testParam:'Default Value',
  aboutBigTextYoffsetParam:'',
  showAboutBigTextParam:'',
  aboutThreeContainersYoffsetParam:'',
  showAboutThreeContainers:'',
  galleryHeadlineYoffsetParam:'',
  showGalleryHeadlineParam:'',
  asyncMarginTopParam:'',
  galleryPickedImageParam:require("../img/jkresized.jpg"),
  showGalleryImagesParam:'',
  aboutTeamYoffsetParam:'',
  showAboutTeamParam:'',
  videoGalleryYoffsetParam:'',
  showVideoGalleryParam:'',
  contactYoffsetParam:'',
  openBigPictureParam:'',
  engSrbParam:'eng',

  error: {
    message: 'Default Message',
    isShown: false,
  }
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case TEST_ACTION:
      const testState = Object.assign({}, state);
      testState.testParam = action.testParam;
      return testState;
    case ABOUTBIGTEXTYOFFSET_ACTION:
      const aboutBigTextYoffsetState = Object.assign({}, state);
      aboutBigTextYoffsetState.aboutBigTextYoffsetParam = action.aboutBigTextYoffsetParam;
      return aboutBigTextYoffsetState;
    case SHOWABOUTBIGTEXT_ACTION:
      const showAboutBigTextState = Object.assign({}, state);
      showAboutBigTextState.showAboutBigTextParam = action.showAboutBigTextParam;
      return showAboutBigTextState;
    case ABOUTTHREECONTAINERSYOFFSET_ACTION:
      const aboutThreeContainersYoffsetState = Object.assign({}, state);
      aboutThreeContainersYoffsetState.aboutThreeContainersYoffsetParam = action.aboutThreeContainersYoffsetParam;
      return aboutThreeContainersYoffsetState;
    case SHOWABOUTTHREECONTAINERS_ACTION:
      const showAboutThreeContainersState = Object.assign({}, state);
      showAboutThreeContainersState.showAboutThreeContainersParam = action.showAboutThreeContainersParam;
      return showAboutThreeContainersState;
    case GALLERYHEADLINEYOFFSET_ACTION:
      const galleryHeadlineYoffsetState = Object.assign({}, state);
      galleryHeadlineYoffsetState.galleryHeadlineYoffsetParam = action.galleryHeadlineYoffsetParam;
      return galleryHeadlineYoffsetState;
    case SHOWAGALLERYHEADLINE_ACTION:
      const showGalleryHeadlineState = Object.assign({}, state);
      showGalleryHeadlineState.showGalleryHeadlineParam = action.showGalleryHeadlineParam;
      return showGalleryHeadlineState;
    case ASYNCMARGINTOP_ACTION:
      const asyncMarginTopState = Object.assign({}, state);
      asyncMarginTopState.asyncMarginTopParam = action.asyncMarginTopParam;
      return asyncMarginTopState;
    case GALLERYPICKEDIMAGE_ACTION:
      const galleryPickedImageState = Object.assign({}, state);
      galleryPickedImageState.galleryPickedImageParam = action.galleryPickedImageParam;
      return galleryPickedImageState;
    case SHOWGALLERYIMAGES_ACTION:
      const showGalleryImagesState = Object.assign({}, state);
      showGalleryImagesState.showGalleryImagesParam = action.showGalleryImagesParam;
      return showGalleryImagesState;
    case ABOUTTEAMYOFFSET_ACTION:
      const aboutTeamYoffsetState = Object.assign({}, state);
      aboutTeamYoffsetState.aboutTeamYoffsetParam = action.aboutTeamYoffsetParam;
      return aboutTeamYoffsetState;
    case SHOWABOUTTEAM_ACTION:
      const showAboutTeamState = Object.assign({}, state);
      showAboutTeamState.showAboutTeamParam = action.showAboutTeamParam;
    return showAboutTeamState;
    case VIDEOGALLERYYOFFSET_ACTION:
      const videoGalleryYoffsetState = Object.assign({}, state);
      videoGalleryYoffsetState.videoGalleryYoffsetParam = action.videoGalleryYoffsetParam;
      return videoGalleryYoffsetState;
    case SHOWVIDEOGALLERY_ACTION:
      const showVideoGalleryState = Object.assign({}, state);
      showVideoGalleryState.showVideoGalleryParam = action.showVideoGalleryParam;
    return showVideoGalleryState;
    case CONTACTYOFFSET_ACTION:
      const contactYoffsetState = Object.assign({}, state);
      contactYoffsetState.contactYoffsetParam = action.contactYoffsetParam;
    return contactYoffsetState;
    case OPENBIGPICTURE_ACTION:
      const openBigPictureState = Object.assign({}, state);
      openBigPictureState.openBigPictureParam = action.openBigPictureParam;
    return openBigPictureState;
    case ENGSRB_ACTION:
      const engSrbState = Object.assign({}, state);
      engSrbState.engSrbParam = action.engSrbParam;
    return engSrbState;


    default:
      return state;


  }
}

export default reducer;
