import React from 'react';
import '../css/contact.css';
import {connect} from 'react-redux';
import { AiFillMobile , AiFillMail , AiFillFacebook , AiFillInstagram } from "react-icons/ai";
import { contactYoffsetAction } from '../actions/main';


class Contact extends React.Component {
  constructor(props) {
   super(props);
   this.wrapper = React.createRef();
   this.state = {
     windowWidth: window.innerWidth,
     windowHeight: window.innerHeight,
   }
 }
 updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    this.props.dispatch(contactYoffsetAction( this.wrapper ? this.wrapper.current.offsetTop : null ));

 }
 componentDidMount() {
   this.props.dispatch(contactYoffsetAction( this.wrapper ? this.wrapper.current.offsetTop : null ));
 }
 render(){
   const { engSrbParam } = this.props;
   return (
     <div  style={{zIndex:10}} ref={this.wrapper} className='contact-wrapper'>
       <div className='contact-message'>
         { engSrbParam === 'eng' ?  'Feel free to  contact us!' : 'Kontaktirajte nas!' }
       </div>
       <div className='contact-logo'/>
       <div className='contact-headline'> Raja Empire Bengal Cattery</div>
       <div className='contact-phone-number'>
         <a href="tel:18005551212"> <AiFillMobile /> +381603520223</a>
       </div>
       <div className='contact-email'>
         <a href = "mailto: abc@example.com"><AiFillMail /> miaskoric26@gmail.com</a>
       </div>
       <div className='contact-social-networks'>
         <a style={{color:'black' , margin:'2%' , height:50 , width:50}} href = "https://www.facebook.com/people/Raja-Empire-Bengal-Cattery/100063857314989/"><AiFillFacebook size={25} /></a>
         <a style={{color:'black' , margin:'2%' , height:50 , width:50}} href = "http://www.instagram.com/raja_empire_bengal_cattery"><AiFillInstagram size={25}/></a>
       </div>
       <div className='contact-copyright'>
         ©2022 Djordjevic Nikola. All Rights Reserved.
       </div>


     </div>
   );
 }


}

const mapStateToProps = (state) => {
  const testParam = state.testParam;
  const engSrbParam = state.engSrbParam;


  return {
    testParam,
    engSrbParam
  }
}
export default connect(mapStateToProps)(Contact);
