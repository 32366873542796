
import Navigation from './Navigation';
import HomeMessage from './HomeMessage';
import Wallpaper from './Wallpaper';
import {showAboutThreeContainersAction} from '../actions/main';
import GalleryAbsolutePicture from './gallery/GalleryAbsolutePicture';

import {connect} from 'react-redux';
import React from 'react';


class Home extends React.Component {
  constructor(props) {
   super(props);
   this.state = {
     windowWidth: window.outerWidth,
     windowHeight: window.outerHeight-window.outerHeight/10,
     appStarted: false,
     triangleTrigger:false,
     arrowAnimation: false,
     delayedArrayBounce: false,
     logoDisappear: false,
     logoShow: false,
     moveLogo: false
   }
 }
 updateDimensions = () => {
    this.setState({ windowWidth: window.outerWidth});


  }
 componentDidMount() {
   window.addEventListener('resize', this.updateDimensions);
   setTimeout(()=>{
      this.setState({
        appStarted: true
      })
    },1000)
    setTimeout(()=>{
       this.setState({
         triangleTrigger: true
       })
     },1500)
    setTimeout(()=>{
       this.setState({
         arrowAnimation: true
       })
     },3000)
     setTimeout(()=>{
        this.setState({
          delayedArrayBounce: true
        })
      },4000)
      setTimeout(()=>{
         this.setState({
           logoDisappear: true
         })
       },1000)
       setTimeout(()=>{
          this.setState({
            moveLogo: true
          })
        },2000)
       setTimeout(()=>{
          this.setState({
            logoShow: true
          })
        },3000)
 }
 componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
 render(){
   const { engSrbParam } = this.props;
   return (
     <div style={{  display: 'flex' , justifyContent: 'center'}}>
     <GalleryAbsolutePicture/>

       <Wallpaper/>
       <Navigation/>
       <HomeMessage/>
       <div style={{
         left: this.state.logoShow ? '3%' : this.state.moveLogo ? '3%':  window.innerWidth/2 - 50,
         top: this.state.logoShow ? '3%' : this.state.moveLogo ? '3%': window.innerHeight/2 - 50
       }}
       className={this.state.logoShow ? 'logo-after' : this.state.logoDisappear ? 'logo' : 'logo-before'}/>
       <div style={{height: this.state.windowHeight , width: '100%' , left:this.state.triangleTrigger ? '-33%' : '-100%' , top: this.state.triangleTrigger ? -window.innerWidth / 4.5 : -this.state.windowHeight , display:window.innerWidth - window.innerWidth/2.5 < window.innerHeight ? 'none' : 'block'}} className='triangle-top'/>
       <div style={{height:this.state.windowHeight , width:'100%' , right:this.state.triangleTrigger ? -window.innerWidth / 4 : '-100%' , bottom: this.state.triangleTrigger ? -window.innerWidth / 5.5 : -this.state.windowHeight , display:window.innerWidth - window.innerWidth/2.5 < window.innerHeight ? 'none' : 'block'}} className='triangle-bottom'/>

       <div style={{ backgroundColor:'black' ,left:0,borderTop: '5px solid',  bottom: this.state.appStarted ? -window.innerHeight :0 }} className='rectangle'/>
       <div style={{ backgroundColor:'black' ,left:0,borderBottom: '5px solid',  top: this.state.appStarted ? -window.innerHeight  : 0 }} className='rectangle'/>

       <div style={{ left: window.innerWidth / 2 - 150 , top:window.innerHeight / 2  - 150 , opacity: this.state.logoDisappear ? 0 : 1 }} className='starting-logo-hexagon'>
         <div className="starting-logo-hexagon-inside"/>
       </div>
       <div onClick={()=>{
         window.scrollTo({top: window.innerHeight, behavior: 'smooth'});
         this.props.dispatch(showAboutThreeContainersAction(true));
       }} className="arrow-button-wrapper">
      <ul className={this.state.delayedArrayBounce ? 'loading-frame-after' : this.state.arrowAnimation ? 'loading-frame' : 'loading-frame-before'}>
      <li className='circle'></li>
      <li className='circle'></li>
      <li className='circle'></li>
      <li className='circle'></li>
      <li className='circle'></li>
      <li className='circle'></li>
      <li className='circle'></li>
      <li className='circle'></li>
      <li className='circle'></li>
      <li className='circle'></li>
    </ul>
    { engSrbParam === 'eng' ?
    <div className='loading-frame-text'>
     <div className='arrow-button-letters'>C</div>
     <div className='arrow-button-letters'>H</div>
     <div className='arrow-button-letters'>E</div>
     <div className='arrow-button-letters'>C</div>
     <div className='arrow-button-letters'>K</div>
     <div className='arrow-button-letters'>O</div>
     <div className='arrow-button-letters'>U</div>
     <div className='arrow-button-letters'>R</div>
     <div className='arrow-button-letters'>C</div>
     <div className='arrow-button-letters'>A</div>
     <div className='arrow-button-letters'>T</div>
     <div className='arrow-button-letters'>T</div>
     <div className='arrow-button-letters'>E</div>
     <div className='arrow-button-letters'>R</div>
     <div className='arrow-button-letters'>Y</div>
    </div>
    :
    <div className='loading-frame-text'>
     <div className='arrow-button-letters'>S</div>
     <div className='arrow-button-letters'>A</div>
     <div className='arrow-button-letters'>Z</div>
     <div className='arrow-button-letters'>N</div>
     <div className='arrow-button-letters'>A</div>
     <div className='arrow-button-letters'>J</div>
     <div className='arrow-button-letters'>T</div>
     <div className='arrow-button-letters'>E</div>
     <div className='arrow-button-letters'>V</div>
     <div className='arrow-button-letters'>I</div>
     <div className='arrow-button-letters'>Š</div>
     <div className='arrow-button-letters'>E</div>
    </div>
    }
    </div>

     </div>
   );
 }

}

const mapStateToProps = (state) => {
  const testParam = state.testParam;
  const engSrbParam = state.engSrbParam;


  return {
    testParam,
    engSrbParam
  }
}
export default connect(mapStateToProps)(Home);
