import '../../css/about.css'
import Navigation from '../Navigation';
import HomeMessage from '../HomeMessage';
import Wallpaper from '../Wallpaper';
import AboutBigText from './AboutBigText';
import AboutThreeContainers from './AboutThreeContainers';
import AboutMeetTheFamilly from './AboutMeetTheFamilly';

import {connect} from 'react-redux';
import React from 'react';
class About extends React.Component {
  constructor(props) {
   super(props);
   this.state = {
     windowWidth: window.innerWidth,
     windowHeight: window.innerHeight,

   }
 }
 updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  }
 componentDidMount() {
   window.addEventListener('resize', this.updateDimensions);
 }
 componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
 render(){
   return (
     <div style={{ display: 'flex' , flexDirection:'column' , alignItems:'center' , justifyContent: 'center' , background:'black' , width: window.innerWidth , zIndex:100}}>
       <AboutBigText/>
       <AboutThreeContainers/>
       <AboutMeetTheFamilly/>
     </div>
   );
 }

}

const mapStateToProps = (state) => {
  const testParam = state.testParam;


  return {
    testParam
  }
}
export default connect(mapStateToProps)(About);
