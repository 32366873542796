import '../../css/gallery.css'
import {connect} from 'react-redux';
import React from 'react';
import GalleryHexagonSection from './GalleryHexagonSection';
import GalleryBigPicture from './GalleryBigPicture';
import GalleryAbsolutePicture from './GalleryAbsolutePicture';
import { galleryHeadlineYoffsetAction } from '../../actions/main';

class Gallery extends React.Component {
  constructor(props) {
   super(props);
   this.wrapper = React.createRef();
   this.state = {
     windowWidth: window.innerWidth,
     windowHeight: window.innerHeight,

   }
 }
 updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    this.props.dispatch(galleryHeadlineYoffsetAction( this.wrapper ? this.wrapper.current.offsetTop : null ));


  }
 componentDidMount() {
   window.addEventListener('resize', this.updateDimensions);
   this.props.dispatch(galleryHeadlineYoffsetAction( this.wrapper ? this.wrapper.current.offsetTop : null ));
 }
 componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
 render(){
   const { showGalleryHeadlineParam , engSrbParam } = this.props;
   return (
     <div  style={{ display: 'flex' , flexDirection:'column' , justifyContent: 'center' , background:'black' ,width: window.innerWidth , zIndex:100 , marginBottom:'5%'}}>
       <div className='gallery-headline-border-left' style={{marginLeft:showGalleryHeadlineParam ? 0 : '-40%' ,transition: 'all 1s ease-out', width:'30%' }}/>
       { engSrbParam === 'eng' ?
       <div className='gallery-headline-text' style={{opacity: showGalleryHeadlineParam ? 1:0 , transition: 'all 1s ease-out'}}>
         <p className='gallery-headline-char-wrapper'>G</p>
         <p className='gallery-headline-char-wrapper'>a</p>
         <p className='gallery-headline-char-wrapper'>l</p>
         <p className='gallery-headline-char-wrapper'>l</p>
         <p className='gallery-headline-char-wrapper'>e</p>
         <p className='gallery-headline-char-wrapper'>r</p>
         <p className='gallery-headline-char-wrapper'>y</p>
       </div>
       :
       <div className='gallery-headline-text' style={{opacity: showGalleryHeadlineParam ? 1:0 , transition: 'all 1s ease-out'}}>
         <p className='gallery-headline-char-wrapper'>G</p>
         <p className='gallery-headline-char-wrapper'>a</p>
         <p className='gallery-headline-char-wrapper'>l</p>
         <p className='gallery-headline-char-wrapper'>e</p>
         <p className='gallery-headline-char-wrapper'>r</p>
         <p className='gallery-headline-char-wrapper'>i</p>
         <p className='gallery-headline-char-wrapper'>j</p>
         <p className='gallery-headline-char-wrapper'>a</p>
       </div>
       }
       <div ref={this.wrapper} className='gallery-headline-border-right' style={{marginLeft:showGalleryHeadlineParam ? '70%': '110%' , transition: 'all 1s ease-out' , width:'30%' , marginBottom:window.innerHeight/10}}/>
       <div style={{position:'relative' ,display:'flex' , flexDirection:'row' }}>
         <GalleryHexagonSection />
         {
           // window.matchMedia("(min-width: 1000px)").matches
           // ?
           // <GalleryBigPicture/>
           // :

         }
       </div>

     </div>
   );
 }

}

const mapStateToProps = (state) => {
  const showGalleryHeadlineParam = state.showGalleryHeadlineParam;
  const engSrbParam = state.engSrbParam;

  return {
    showGalleryHeadlineParam,
    engSrbParam
  }
}
export default connect(mapStateToProps)(Gallery);
