import {connect} from 'react-redux';
import React from 'react';
import { aboutTeamYoffsetAction } from '../../actions/main';

class AboutMeetTheFamilly extends React.Component {
  constructor(props) {
   super(props);
   this.wrapper = React.createRef();
   this.state = {
     windowWidth: window.innerWidth,
     windowHeight: window.innerHeight,
     minimize: window.matchMedia("(min-width: 1000px)").matches ? false : true
   };
  }
 componentDidMount() {
   this.props.dispatch(aboutTeamYoffsetAction( this.wrapper ? this.wrapper.current.offsetTop : null ));
   window.addEventListener('resize', this.updateDimensions);
 }
 updateDimensions = () => {
   // this.props.dispatch(aboutTeamYoffsetAction( this.wrapper ? this.wrapper.current.offsetTop : null ));
   console.log(window.matchMedia("(min-width: 1000px)").matches)
   window.matchMedia("(min-width: 1000px)").matches ? this.setState({minimize:false}) : this.setState({minimize:true})
 }
 componentWillUnmount() {
   window.removeEventListener('resize', this.updateDimensions);
 }
  render(){
    const { showAboutTeamParam , engSrbParam } =this.props;
    return (
      <div  style={{  width:'100%' , marginBottom:'20%'}}>
        <div  className='gallery-headline-border-left' style={{marginLeft: showAboutTeamParam ? 0 : '-35%' ,transition: 'all 1s ease-out', width:'30%' }}/>
        <div ref={this.wrapper} className='gallery-headline-text' style={{opacity:  showAboutTeamParam ? 1 : 0 , transition: 'all 1s ease-out'}}>
        {window.matchMedia("(min-width: 1000px)").matches ?
          (engSrbParam === 'eng' ?
          <>
          <p className='gallery-headline-char-wrapper'>M</p>
          <p className='gallery-headline-char-wrapper'>e</p>
          <p className='gallery-headline-char-wrapper'>e</p>
          <p style={{marginRight:'5%'}} className='gallery-headline-char-wrapper'>t</p>
          <p className='gallery-headline-char-wrapper'>t</p>
          <p className='gallery-headline-char-wrapper'>h</p>
          <p style={{marginRight:'5%'}} className='gallery-headline-char-wrapper'>e</p>
          <p className='gallery-headline-char-wrapper'>f</p>
          <p className='gallery-headline-char-wrapper'>a</p>
          <p className='gallery-headline-char-wrapper'>m</p>
          <p className='gallery-headline-char-wrapper'>i</p>
          <p className='gallery-headline-char-wrapper'>l</p>
          <p className='gallery-headline-char-wrapper'>l</p>
          <p className='gallery-headline-char-wrapper'>y</p>
          </>
          :
          <>
          <p className='gallery-headline-char-wrapper'>N</p>
          <p className='gallery-headline-char-wrapper'>a</p>
          <p className='gallery-headline-char-wrapper'>š</p>
          <p style={{marginRight:'5%'}} className='gallery-headline-char-wrapper'>a</p>
          <p className='gallery-headline-char-wrapper'>P</p>
          <p className='gallery-headline-char-wrapper'>o</p>
          <p className='gallery-headline-char-wrapper'>r</p>
          <p className='gallery-headline-char-wrapper'>o</p>
          <p className='gallery-headline-char-wrapper'>d</p>
          <p className='gallery-headline-char-wrapper'>i</p>
          <p className='gallery-headline-char-wrapper'>c</p>
          <p className='gallery-headline-char-wrapper'>a</p>
          </>
          )
          :
          (engSrbParam ==='eng' ?
          <>
          <p className='gallery-headline-char-wrapper'>F</p>
          <p className='gallery-headline-char-wrapper'>a</p>
          <p className='gallery-headline-char-wrapper'>m</p>
          <p className='gallery-headline-char-wrapper'>i</p>
          <p className='gallery-headline-char-wrapper'>l</p>
          <p className='gallery-headline-char-wrapper'>l</p>
          <p className='gallery-headline-char-wrapper'>y</p>
          </>
          :
          <>
          <p className='gallery-headline-char-wrapper'>P</p>
          <p className='gallery-headline-char-wrapper'>o</p>
          <p className='gallery-headline-char-wrapper'>r</p>
          <p className='gallery-headline-char-wrapper'>o</p>
          <p className='gallery-headline-char-wrapper'>d</p>
          <p className='gallery-headline-char-wrapper'>i</p>
          <p className='gallery-headline-char-wrapper'>c</p>
          <p className='gallery-headline-char-wrapper'>a</p>
          </>
          )
        }


         </div>
        <div  className='gallery-headline-border-right' style={{marginLeft: showAboutTeamParam ? '70%' : '110%', transition: 'all 1s ease-out' , width:'30%' , marginBottom:'10%'}}/>
        <div style={{opacity: showAboutTeamParam ? 1 : 0 , transition: 'all 1s ease-out'}}>
          <div className='about-top-and-bot-cats-wrapper'>
            {
            !this.state.minimize
            ?
            <div className='about-left-cat-wrapper'>
             <div style={{flex:1 ,display:'flex' , flexDirection:'column'  , textAlign:'center'}}>
               <div className='about-female-cats-names'>
                 <span className='about-span-left' style={{display:'table-cell' , verticalAlign:'middle' , color:'#fff'}}>
                   V'Lolla Spotty Gold
                 </span>
               </div>
               <div className='about-female-cats'>
                 <span style={{display:'table-cell' , verticalAlign:'middle', color:'#fff', color:'#fff' , fontFamily: 'Rubik, sans-serif' ,textAlign:'justify'}}>
                 {
                  engSrbParam === 'eng' ?
                  `Lola is a show class cat. At the show, she was declared CACJ (Junior Champion), 2xBIS (Best in Show),
                  2X BOB (4th place). She comes to us from Russia.`
                  :
                  `Lola je show class cat. Na izložbi je proglašena za CACJ (Junior Champion), 2xBIS(Best in Show), 2X BOB (4.mesto) Ona nam dolazi iz Rusije.`
                 }
                 </span>
               </div>
             </div>
              <div style={{backgroundImage: "url(" + require("../../img/cat4small.jpg") + ")" }} className='about-cat-image-left'/>
            </div>
            :
            <div style={{marginBottom: '2%'}}  className='about-right-cat-wrapper'>
              <div style={{backgroundImage: "url(" + require("../../img/cat5small.jpg") + ")" }} className='about-cat-image-right'/>
              <div style={{flex:1 ,diplay:'flex'}}>
                <div className='about-female-cats-names'>
                  <span className='about-span-right' style={{display:'table-cell' , verticalAlign:'middle' , color:'#fff'}}>
                    Sundiata Raja
                  </span>
                </div>
                <div className='about-female-cats'>
                  <span style={{display:'table-cell' , verticalAlign:'middle', color:'#fff', color:'#fff' , fontFamily: 'Rubik, sans-serif' ,textAlign:'justify'}}>
                  { engSrbParam ==='eng' ?
                  `Sundiata Raja, the only male cat and the father of our kittens, comes from Portugal and weighs 7 kg.`
                  :
                  `Sundiata Raja, jedini mačak i otac naših mačića, dolazi iz Portugala i težak je 7 kg.`
                  }
                  </span>
                </div>
              </div>
            </div>
            }
            <div style={{marginBottom:window.matchMedia("(min-width: 1000px)").matches ? 0 : '2%'}}  className='about-right-cat-wrapper'>
              <div style={{backgroundImage: "url(" + require("../../img/zara.jpg") + ")" }}  className='about-cat-image-right'/>
              <div style={{flex:1 ,diplay:'flex'}}>
                <div className='about-female-cats-names'>
                  <span className='about-span-right' style={{display:'table-cell' , verticalAlign:'middle' , color:'#fff'}}>
                    Goldycat XZara
                  </span>
                </div>
                <div className='about-female-cats'>
                  <span style={{display:'table-cell' , verticalAlign:'middle', color:'#fff', color:'#fff' , fontFamily: 'Rubik, sans-serif' ,textAlign:'justify'}}>
                  { engSrbParam === 'eng' ?
                    `Zara is the oldest female. Her coat has the most glitter of any cat.She came from Russia.`
                    :
                    `Zara je najstarija ženka. Njena dlaka ima najviše sjaja od svih mačaka. Došla je iz Rusije.`
                  }
                  </span>
                </div>
              </div>
            </div>


          </div>
          {
          !this.state.minimize
          ?
          ////////
          // <div className='about-maincat-wrapper' style={{ marginLeft:'40%' , width:'calc(20% - 10px)' , height:'400px'  }}>
          //   <div className='about-maincat-wrapper-image'/>
          //   <div className='about-maincat-wrapper-content'>
          //   <div className='about-female-cats-names'>
          //     <span className='about-span-left' style={{display:'table-cell' , verticalAlign:'middle' , color:'#fff'}}>
          //       Sundiata Raja
          //     </span>
          //   </div>
          //   <div className='about-female-cats'>
          //     <span style={{display:'table-cell' , verticalAlign:'middle', color:'#fff', color:'#fff' , fontFamily: 'Rubik, sans-serif' ,textAlign:'justify'}}>
          //     { engSrbParam === 'eng' ?
          //       `Sundiata Raja, the only male cat and the father of our kittens, comes from Portugal and weighs 7 kg.`
          //       :
          //       `Sundiata Raja, jedini mačak i otac naših mačića, dolazi iz Portugala i težak je 7 kg.`
          //     }
          //     </span>
          //   </div>
          //   </div>
          // </div>
          <div className='about-top-and-bot-cats-wrapper'>
            {
            !this.state.minimize
            ?
            <div className='about-left-cat-wrapper'>
             <div style={{flex:1 ,display:'flex' , flexDirection:'column'  , textAlign:'center'}}>
               <div className='about-female-cats-names'>
                 <span className='about-span-left' style={{display:'table-cell' , verticalAlign:'middle' , color:'#fff'}}>
                   Sundiata Raja
                 </span>
               </div>
               <div className='about-female-cats'>
                 <span style={{display:'table-cell' , verticalAlign:'middle', color:'#fff', color:'#fff' , fontFamily: 'Rubik, sans-serif' ,textAlign:'justify'}}>
                 {
                  engSrbParam === 'eng' ?
                  `Sundiata Raja, the only male cat and the father of our kittens, comes from Portugal and weighs 7 kg.`
                  :
                  `Sundiata Raja, jedini mačak i otac naših mačića, dolazi iz Portugala i težak je 7 kg.`
                 }
                 </span>
               </div>
             </div>
              <div style={{backgroundImage: "url(" + require("../../img/rajasquared.jpg") + ")" }} className='about-cat-image-left'/>
            </div>
            :
            <div style={{marginBottom: '2%'}}  className='about-right-cat-wrapper'>
              <div style={{backgroundImage: "url(" + require("../../img/cat5small.jpg") + ")" }} className='about-cat-image-right'/>
              <div style={{flex:1 ,diplay:'flex'}}>
                <div className='about-female-cats-names'>
                  <span className='about-span-right' style={{display:'table-cell' , verticalAlign:'middle' , color:'#fff'}}>
                    Sundiata Raja
                  </span>
                </div>
                <div className='about-female-cats'>
                  <span style={{display:'table-cell' , verticalAlign:'middle', color:'#fff', color:'#fff' , fontFamily: 'Rubik, sans-serif' ,textAlign:'justify'}}>
                  { engSrbParam ==='eng' ?
                  `Sundiata Raja, the only male cat and the father of our kittens, comes from Portugal and weighs 7 kg.`
                  :
                  `Sundiata Raja, jedini mačak i otac naših mačića, dolazi iz Portugala i težak je 7 kg.`
                  }
                  </span>
                </div>
              </div>
            </div>
            }
            <div style={{marginBottom:window.matchMedia("(min-width: 1000px)").matches ? 0 : '2%'}}  className='about-right-cat-wrapper'>
              <div style={{backgroundImage: "url(" + require("../../img/viola.jpg") + ")" }}  className='about-cat-image-right'/>
              <div style={{flex:1 ,diplay:'flex'}}>
                <div className='about-female-cats-names'>
                  <span className='about-span-right' style={{display:'table-cell' , verticalAlign:'middle' , color:'#fff'}}>
                    Raja Empire Viola
                  </span>
                </div>
                <div className='about-female-cats'>
                  <span style={{display:'table-cell' , verticalAlign:'middle', color:'#fff', color:'#fff' , fontFamily: 'Rubik, sans-serif' ,textAlign:'justify'}}>
                  { engSrbParam === 'eng' ?
                    `Our youngest and most successful family member - OUR PRIDE. At the WCF exhibition in Romania, at only 4 months old, she was declared the most beautiful Bengal cat of the exhibition.`
                    :
                    `Nas najmladji i najuspesniji clan porodice-NAS PONOS. Na WCF izlozbi u Rumuniji sa samo 4 meseca starosti proglasena je za najlepsu bengalsku macku izlozbe.`
                  }
                  </span>
                </div>
              </div>
            </div>
          </div>
          ////////
          :
          <div className='about-top-and-bot-cats-wrapper'>
            <div style={{marginBottom: '2%' }}  className='about-right-cat-wrapper'>
              <div style={{backgroundImage: "url(" + require("../../img/cat4small.jpg") + ")" }} className='about-cat-image-right'/>
              <div style={{flex:1 ,diplay:'flex'}}>
                <div className='about-female-cats-names'>
                  <span className='about-span-right' style={{display:'table-cell' , verticalAlign:'middle' , color:'#fff'}}>
                    V'Lolla Spotty Gold
                  </span>
                </div>
                <div className='about-female-cats'>
                  <span style={{display:'table-cell' , verticalAlign:'middle', color:'#fff', color:'#fff' , fontFamily: 'Rubik, sans-serif' ,textAlign:'justify'}}>
                  { engSrbParam === 'eng' ?
                    `Lola is a show class cat. At the show, she was declared JCH (Junior Champion), 2xBIS (Best in Show), 2X BOB (4th place). She comes to us from Russia.`
                    :
                    `Lola je show class cat. Na izložbi je proglašena za CACJ (Junior Champion), 2xBIS(Best in Show), 2X BOB (4.mesto) Ona nam dolazi iz Rusije.`
                  }
                  </span>
                </div>
              </div>
            </div>

            <div style={{marginBottom:window.matchMedia("(min-width: 1000px)").matches ? 0 : '2%'}}  className='about-right-cat-wrapper'>
              <div style={{backgroundImage: "url(" + require("../../img/viola.jpg") + ")" }}  className='about-cat-image-right'/>
              <div style={{flex:1 ,diplay:'flex'}}>
                <div className='about-female-cats-names'>
                  <span className='about-span-right' style={{display:'table-cell' , verticalAlign:'middle' , color:'#fff'}}>
                    Raja Empire Viola
                  </span>
                </div>
                <div className='about-female-cats'>
                  <span style={{display:'table-cell' , verticalAlign:'middle', color:'#fff', color:'#fff' , fontFamily: 'Rubik, sans-serif' ,textAlign:'justify'}}>
                  { engSrbParam === 'eng' ?
                    `Our youngest and most successful family member - OUR PRIDE. At the WCF exhibition in Romania, at only 4 months old, she was declared the most beautiful Bengal cat of the exhibition.`
                    :
                    `Nas najmladji i najuspesniji clan porodice-NAS PONOS. Na WCF izlozbi u Rumuniji sa samo 4 meseca starosti proglasena je za najlepsu bengalsku macku izlozbe.`
                  }
                  </span>
                </div>
              </div>
            </div>
          </div>
          }
          <div className='about-top-and-bot-cats-wrapper'>
          {
          !this.state.minimize
          ?
            <div className='about-left-cat-wrapper'>
              <div style={{flex:1 ,diplay:'flex'}}>
                <div className='about-female-cats-names'>
                  <span className='about-span-left' style={{display:'table-cell' , verticalAlign:'middle' , color:'#fff'}}>
                    GellaKovalski Alisa-Gina
                  </span>
                </div>
                <div className='about-female-cats'>
                  <span style={{display:'table-cell' , verticalAlign:'middle', color:'#fff' , fontFamily: 'Rubik, sans-serif' ,textAlign:'justify'}}>
                  { engSrbParam === 'eng' ?
                    `Gina's beauty comes to us from Russia. She is the biggest female of all. She was declared BIV (Best of all Bengalis) at the exhibition.`
                    :
                    `Gina lepotica nam dolazi iz Rusije. Ona je najveća ženka od svih. Na izložbi je proglašena za BIV (najbolja od svih bengalca).`
                  }
                  </span>
                </div>
              </div>
               <div style={{backgroundImage: "url(" + require("../../img/gina.jpg") + ")" }}  className='about-cat-image-left'/>
            </div>
            :
            <div style={{marginBottom: '2%'}} className='about-right-cat-wrapper'>
              <div style={{backgroundImage: "url(" + require("../../img/gina.jpg") + ")" }}  className='about-cat-image-right'/>
              <div style={{flex:1 ,diplay:'flex'}}>
                <div className='about-female-cats-names'>
                  <span className='about-span-right' style={{display:'table-cell' , verticalAlign:'middle' , color:'#fff'}}>
                    GellaKovalski Alisa-Gina
                  </span>
                </div>
                <div className='about-female-cats'>
                  <span style={{display:'table-cell' , verticalAlign:'middle', color:'#fff', color:'#fff' , fontFamily: 'Rubik, sans-serif' ,textAlign:'justify' }}>
                  { engSrbParam === 'eng' ?
                    `Gina's beauty comes to us from Russia. She is the biggest female of all. She was declared BIV (Best of all Bengalis) at the exhibition.`
                    :
                    `Gina lepotica nam dolazi iz Rusije. Ona je najveća ženka od svih. Na izložbi je proglašena za BIV (najbolja od svih bengalca).`
                  }
                  </span>
                </div>
              </div>
            </div>
          }
            <div className='about-right-cat-wrapper'>
              <div style={{backgroundImage: "url(" + require("../../img/maya.jpg") + ")" }}  className='about-cat-image-right'/>
              <div style={{flex:1 ,diplay:'flex'}}>
                <div className='about-female-cats-names'>
                  <span className='about-span-right' style={{display:'table-cell' , verticalAlign:'middle' , color:'#fff'}}>
                    Sirocco Maya
                  </span>
                </div>
                <div className='about-female-cats'>
                  <span style={{display:'table-cell' , verticalAlign:'middle', color:'#fff', color:'#fff' , fontFamily: 'Rubik, sans-serif' ,textAlign:'justify' }}>
                  { engSrbParam === 'eng' ?
                    `We brought Maya from Poland. She is the youngest member of the family. Excellent rosettes, color, contrast and glitter are its characteristics.`
                    :
                    `Mayu smo doveli iz Poljske. Ona je najmladji clan porodice. Odlicne rozete,boja,kontrast i sjaj su njene karakteristike.`
                  }
                  </span>
                </div>
              </div>
            </div>
          </div>



          <div className='about-top-and-bot-cats-wrapper'>
          {
          !this.state.minimize
          ?
            <div className='about-left-cat-wrapper'>
              <div style={{flex:1 ,diplay:'flex'}}>
                <div className='about-female-cats-names'>
                  <span className='about-span-left' style={{display:'table-cell' , verticalAlign:'middle' , color:'#fff'}}>
                    A'Mia
                  </span>
                </div>
                <div className='about-female-cats'>
                  <span style={{display:'table-cell' , verticalAlign:'middle', color:'#fff' , fontFamily: 'Rubik, sans-serif' ,textAlign:'justify'}}>
                  { engSrbParam === 'eng' ?
                  `Mia achieved fantastic results at the WCF show in Bulgaria. She was declared the most beautiful cat of the exhibition (of all breeds and ages).`
                  :
                  `Mia je postigla fantasticne rezultate na WCF izlozbi u Bugarskoj. Proglasena je za najlepsu macku izlozbe ( od svih rasa i uzrasta).`
                  }
                  </span>
                </div>
              </div>
               <div style={{backgroundImage: "url(" + require("../../img/amia.JPG") + ")" }}  className='about-cat-image-left'/>
            </div>
            :
            <div style={{marginBottom: '2%'}} className='about-right-cat-wrapper'>
              <div style={{backgroundImage: "url(" + require("../../img/amia.JPG") + ")" }}  className='about-cat-image-right'/>
              <div style={{flex:1 ,diplay:'flex'}}>
                <div className='about-female-cats-names'>
                  <span className='about-span-right' style={{display:'table-cell' , verticalAlign:'middle' , color:'#fff'}}>
                    A'Mia
                  </span>
                </div>
                <div className='about-female-cats'>
                  <span style={{display:'table-cell' , verticalAlign:'middle', color:'#fff', color:'#fff' , fontFamily: 'Rubik, sans-serif' ,textAlign:'justify' }}>
                  { engSrbParam === 'eng' ?
                    `Mia achieved fantastic results at the WCF show in Bulgaria. She was declared the most beautiful cat of the exhibition (of all breeds and ages).`
                    :
                    `Mia je postigla fantasticne rezultate na WCF izlozbi u Bugarskoj. Proglasena je za najlepsu macku izlozbe (od svih rasa i uzrasta).`
                  }
                  </span>
                </div>
              </div>
            </div>
          }
            <div className='about-right-cat-wrapper'>
              <div style={{backgroundImage: "url(" + require("../../img/IMG_66154.jpg") + ")" }}  className='about-cat-image-right'/>
              <div style={{flex:1 ,diplay:'flex'}}>
                <div className='about-female-cats-names'>
                  <span className='about-span-right' style={{display:'table-cell' , verticalAlign:'middle' , color:'#fff'}}>
                    Dante Alpamayo
                  </span>
                </div>
                <div className='about-female-cats'>
                  <span style={{display:'table-cell' , verticalAlign:'middle', color:'#fff', color:'#fff' , fontFamily: 'Rubik, sans-serif' ,textAlign:'justify' }}>
                  { engSrbParam === 'eng' ?
                    `A male who has been achieving fantastic results since the moment he started going to exhibitions. Kitten Champion, International Champion are the titles he has for now.`
                    :
                    `Muzjak koji od trenutka kada je krenuo na izlozbe postize fantasticne rezultate. Kitten Sampion, Junior Sampion, Sampion, Internacionlni Sampion su titule koje on za sada ima.`
                  }
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const showAboutTeamParam = state.showAboutTeamParam;
  const engSrbParam = state.engSrbParam;

  return {
    showAboutTeamParam,
    engSrbParam
  }
}

export default connect(mapStateToProps)(AboutMeetTheFamilly);
