import '../css/homemessage.css';
import {connect} from 'react-redux';
import React from 'react';

class HomeMessage extends React.Component {
  constructor(props) {
   super(props);
   this.state = {
     windowWidth: window.innerWidth,
     windowHeight: window.innerHeight,
     messageAnimation: false
   }
 }
 updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  }
 componentDidMount() {
   window.addEventListener('resize', this.updateDimensions);
   setTimeout(()=>{
      this.setState({
        messageAnimation: true,
      })
    },3100)
 }
 componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
 render(){
   const { engSrbParam } = this.props;
  return (
    <div style={{ height:window.innerHeight/6 , width:window.matchMedia("(max-width: 999px)").matches ? window.innerWidth : window.innerWidth/2.5 , marginTop:window.innerHeight/2.5 }} className={this.state.messageAnimation ? 'message-wrapper' : 'message-wrapper-before'}>
      <div className='title-wrapper'>
        <p className='title'>R</p>
        <p className='title'>a</p>
        <p className='title'>j</p>
        <p className='title'>a</p>
        <p className='title'>E</p>
        <p className='title'>m</p>
        <p className='title'>p</p>
        <p className='title'>i</p>
        <p className='title'>r</p>
        <p className='title'>e</p>
        <p className='title'>B</p>
        <p className='title'>e</p>
        <p className='title'>n</p>
        <p className='title'>g</p>
        <p className='title'>a</p>
        <p className='title'>l</p>
        <p className='title'>C</p>
        <p className='title'>a</p>
        <p className='title'>t</p>
        <p className='title'>t</p>
        <p className='title'>e</p>
        <p className='title'>r</p>
        <p className='title'>y</p>
      </div>
      <div className='text-wrapper'> { engSrbParam === 'eng' ?  'Welcome to the bengal world' : 'Dobrodošli u svet bengalskih mačaka'}</div>
    </div>
  );
}
}
const mapStateToProps = (state) => {
  const testParam = state.testParam;
  const engSrbParam = state.engSrbParam;


  return {
    testParam,
    engSrbParam
  }
}
export default connect(mapStateToProps)(HomeMessage);
