import '../../css/gallery.css'
import {connect} from 'react-redux';
import React from 'react';
import { openBigPictureAction , galleryPickedImageAction } from '../../actions/main';
import JK from '../../img/jkresized.jpg';
import Gallery1 from '../../img/gallerybigptcs/gallery1.jpg';
import Gallery2 from '../../img/gallerybigptcs/gallery2.jpg';
import Gallery3 from '../../img/gallerybigptcs/gallery3.jpg';
import Gallery4 from '../../img/gallerybigptcs/gallery4.jpg';
import Gallery5 from '../../img/gallerybigptcs/gallery5.jpg';
import Gallery6 from '../../img/gallerybigptcs/gallery6.jpg';
import Gallery7 from '../../img/gallerybigptcs/gallery7.jpg';
import Gallery8 from '../../img/gallerybigptcs/gallery8.jpg';
import Gallery9 from '../../img/gallerybigptcs/gallery9.jpg';
import Gallery10 from '../../img/gallerybigptcs/gallery10.jpg';
import Gallery11 from '../../img/gallerybigptcs/gallery11.jpg';
import Gallery12 from '../../img/gallerybigptcs/gallery12.jpg';
import Gallery13 from '../../img/gallerybigptcs/gallery13.jpg';
import Gallery14 from '../../img/gallerybigptcs/gallery14.jpg';
import Gallery15 from '../../img/gallerybigptcs/gallery15.jpg';
import Gallery16 from '../../img/gallerybigptcs/gallery16.jpg';
import Gallery17 from '../../img/gallerybigptcs/gallery17.jpg';
import Cat1 from '../../img/gallerybigptcs/cat1.jpg';
import Cat2 from '../../img/gallerybigptcs/cat2.jpg';
import Cat3 from '../../img/gallerybigptcs/cat3.jpg';
import Cat4 from '../../img/gallerybigptcs/cat4.jpg';
import Cat5 from '../../img/gallerybigptcs/cat5.jpg';
import JK2 from '../../img/gallerybigptcs/jk2.jpg';
import JK3 from '../../img/gallerybigptcs/jk3.jpg';

import NPC1 from '../../img/gallerybigptcs/npc1.JPG';
import NPC2 from '../../img/gallerybigptcs/npc2.JPG';
import NPC3 from '../../img/gallerybigptcs/npc3.JPG';
import NPC4 from '../../img/gallerybigptcs/npc4.JPG';
import NPC5 from '../../img/gallerybigptcs/npc5.JPG';
import NPC6 from '../../img/gallerybigptcs/npc6.JPG';
import NPC7 from '../../img/gallerybigptcs/npc7.JPG';
import NPC8 from '../../img/gallerybigptcs/g1.jpg';
import NPC9 from '../../img/gallerybigptcs/npc9.JPG';
import NPC10 from '../../img/gallerybigptcs/npc10.JPG';
import NPC11 from '../../img/gallerybigptcs/npc11.JPG';
import NPC12 from '../../img/gallerybigptcs/npc12.JPG';
import NPC13 from '../../img/gallerybigptcs/npc13.JPG';
import NPC14 from '../../img/gallerybigptcs/npc14.JPG';
import NPC15 from '../../img/gallerybigptcs/npc15.JPG';
import NPC16 from '../../img/gallerybigptcs/npc16.JPG';
import NPC17 from '../../img/gallerybigptcs/npc17.JPG';
import NPC18 from '../../img/gallerybigptcs/npc18.JPG';
import NPC19 from '../../img/gallerybigptcs/npc19.JPG';
import NPC20 from '../../img/gallerybigptcs/npc20.JPG';
import NPC21 from '../../img/gallerybigptcs/npc21.jpeg';
import NPC22 from '../../img/gallerybigptcs/npc22.jpeg';
import NPC23 from '../../img/gallerybigptcs/npc23.jpeg';
import NPC24 from '../../img/gallerybigptcs/npc24.jpeg';
import G8 from '../../img/gallerybigptcs/g8.jpg';
import NPC25 from '../../img/gallerybigptcs/npc25.jpeg';
import G3 from '../../img/gallerybigptcs/g3.jpg';
import G2 from '../../img/gallerybigptcs/g2.jpg';
import G7 from '../../img/gallerybigptcs/g7.jpg';
import NPC26 from '../../img/gallerybigptcs/npc26.jpeg';
import GT8 from '../../img/thumbnails/g8.jpg';
import GT10 from '../../img/thumbnails/g10.jpg';
import { AiOutlineLeft , AiOutlineRight , AiOutlineClose } from "react-icons/ai";

let galleryArray = [
  JK , Gallery1 , Gallery2 , Gallery3 , Gallery4 , Gallery5 , Gallery6 , Gallery7 ,Gallery8 , Gallery9 , Gallery10 , Gallery11 ,
  Gallery12 , Gallery13 , Gallery14 , Gallery15 , Gallery16 , Gallery17 , Cat1 , Cat2 , Cat3 , Cat4 , Cat5 , JK2 , JK3,
  NPC26, NPC1, NPC2, NPC3, NPC4, NPC5, NPC6, NPC7, NPC8, NPC9, NPC10, NPC11, NPC12, NPC13, NPC14, NPC15, NPC16, NPC17, NPC18, NPC19,
  NPC20, NPC21, NPC22, NPC23, NPC24, G8, NPC25, G3, G2, G7, GT8, GT10
]
class GalleryAbsolutePicture extends React.Component {
  constructor(props) {
   super(props);
   this.state = {
     windowWidth: window.innerWidth,
     windowHeight: window.innerHeight,
     chosenPicture: ''
   }
 }
 updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  }
 componentDidMount() {
   window.addEventListener('resize', this.updateDimensions);
 }
 componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
 }
 array( value) {
   if( value === 'lower'){
     if(galleryArray.indexOf(this.props.galleryPickedImageParam)>0) {
       this.props.dispatch(galleryPickedImageAction( galleryArray[galleryArray.indexOf(this.props.galleryPickedImageParam)-1]))
     }
   }
   if( value === 'higher'){
     if(galleryArray.indexOf(this.props.galleryPickedImageParam)<56) {
       this.props.dispatch(galleryPickedImageAction( galleryArray[galleryArray.indexOf(this.props.galleryPickedImageParam)+1]))
     }
   }
 }

 render(){
   const {   galleryPickedImageParam  , openBigPictureParam} = this.props;

   return (
     <div style={{position:'absolute' ,  display:openBigPictureParam ? 'flex' : 'none' , left:0 , top:window.pageYOffset ,width: '100%' , height:'100%' }}>
       <div style={{position:'relative' ,left:0,top:0, width:'100%' , height:'100%' , backgroundColor:'rgba(0,0,0,0.5)'}}>
         <div className='gallery-big-picture-wrapper' style={{position:'absolute', top:'2%' ,backgroundImage: "url(" + galleryPickedImageParam + ")" , height: this.state.windowHeight - 100}}>
           <AiOutlineClose onClick={()=>{ this.props.dispatch(openBigPictureAction( false ))  }} style={{ position:'absolute' , right:0 , top:0 , color:'white' , width:'40px' , height:'40px' , filter: 'drop-shadow(1px 1px rgba(0,0,0,1))' }}/>
           <AiOutlineRight size="20%" color="white"  onClick={()=>{this.array('higher')}} style={{ position:'absolute' , right:0 , top:'40%' , filter: 'drop-shadow(1px 1px rgba(0,0,0,1))'}}/>
           <AiOutlineLeft size="20%" color="white"  onClick={()=>{this.array('lower')}} style={{ position:'absolute' , left:0 , top:'40%' , filter: 'drop-shadow(1px 1px rgba(0,0,0,1))'}}/>

         </div>
       </div>



     </div>
   );
 }

}

const mapStateToProps = (state) => {
  const galleryPickedImageParam = state.galleryPickedImageParam;
  const openBigPictureParam = state.openBigPictureParam;

  return {
    galleryPickedImageParam,
    openBigPictureParam
  }
}
export default connect(mapStateToProps)(GalleryAbsolutePicture);
