import '../css/navigation.css';
import {connect} from 'react-redux';
import React from 'react';
import { engSrbAction } from '../actions/main';
import { AiOutlineClose } from "react-icons/ai";


class Navigation extends React.Component {
  constructor(props) {
   super(props);
   this.state = {
     windowWidth: window.innerWidth,
     windowHeight: window.innerHeight,
     homeLogoPeaceMounted: false,
     aboutLogoPeaceMounted: false,
     galleryLogoPeaceMounted: false,
     videoGalleryLogoPeaceMounted: false,
     catteryLogoPeaceMounted: false,
     contactLogoPeaceMounted: false,
     triggerBurgerModal: false
   }
 }
 updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  }
 componentDidMount() {
   window.addEventListener('resize', this.updateDimensions);
   console.log(this.props.testParam , 'param');
   setTimeout(()=>{
      this.setState({
        homeLogoPeaceMounted: true
      })
    },2000)
    setTimeout(()=>{
       this.setState({
         aboutLogoPeaceMounted: true,
         galleryLogoPeaceMounted: true,
       })
     },2500)
     setTimeout(()=>{
        this.setState({
          videoGalleryLogoPeaceMounted: true,
        })
      },2800)
      setTimeout(()=>{
         this.setState({
           catteryLogoPeaceMounted: true,
         })
       },3000)
       setTimeout(()=>{
          this.setState({
            contactLogoPeaceMounted: true,
          })
        },3100)
 }
 componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
 changeLanguage( language ) {
   if( language === 'eng' ) this.props.dispatch(engSrbAction( 'srb' ));
   else this.props.dispatch(engSrbAction( 'eng' ));

 }
 render(){
   const { aboutBigTextYoffsetParam , galleryHeadlineYoffsetParam ,videoGalleryYoffsetParam , contactYoffsetParam , engSrbParam  } = this.props;

  return (
    <div className="navigation">
      {
      window.matchMedia("(min-width: 1000px)").matches
      ?
      <div onClick={()=> { window.scrollTo({top: galleryHeadlineYoffsetParam, behavior: 'smooth'})}} className={ this.state.galleryLogoPeaceMounted ? 'outside hex1' : 'outside hex1-before'}>
        <div className="inside">
          <div  className="double-inside pos1">
            {engSrbParam === 'eng' ? 'Gallery' : 'Galerija' }
          </div>
        </div>
      </div>
      :
      null
      }
      <div className='set-burger-margin' style={{opacity: this.state.triggerBurgerModal ? 0 : 1 ,zIndex: 150 ,position: window.matchMedia("(max-width: 1000px)").matches ? 'fixed' : 'absolute' }}
      onClick={()=> { window.matchMedia("(min-width: 1000px)").matches ? window.scrollTo({top: 0, behavior: 'smooth'}) : this.setState({triggerBurgerModal: true}) }} className={ this.state.homeLogoPeaceMounted ? 'outside hex2' : 'outside hex2-before'} >
        <div className="inside">
          <div className="double-inside pos2">
            {engSrbParam === 'eng' ? 'Home' : 'Početna' }
          </div>
        </div>
      </div>

      {
      window.matchMedia("(min-width: 1000px)").matches
      ?
      <div onClick={()=> { window.scrollTo({top: videoGalleryYoffsetParam, behavior: 'smooth'})}} className={ this.state.videoGalleryLogoPeaceMounted ? 'outside hex3' : 'outside hex3-before'}>
        <div className="inside">
          <div className="double-inside pos3">
            Video
          </div>
        </div>
      </div>
      :
      null
      }
      {
      window.matchMedia("(min-width: 1000px)").matches
      ?
      <div onClick={()=> { window.scrollTo({top: aboutBigTextYoffsetParam , behavior: 'smooth'})}} className={ this.state.aboutLogoPeaceMounted ? 'outside hex4' : 'outside hex4-before'}>
        <div className="inside">
          <div className="double-inside pos4">
            {engSrbParam === 'eng' ? 'About' : 'O nama' }
          </div>
        </div>
      </div>
      :
      null
      }
      {
      window.matchMedia("(min-width: 1000px)").matches
      ?
      <div onClick={()=>{ this.changeLanguage( engSrbParam ) }} className={ this.state.catteryLogoPeaceMounted ? 'outside hex5' : 'outside hex5-before'}>
        <div className="inside">
          <div className="double-inside pos5">
            Eng/Srb
          </div>
        </div>
      </div>
      :
      null
      }
      {
      window.matchMedia("(min-width: 1000px)").matches
      ?
      <div onClick={()=> { window.scrollTo({top: contactYoffsetParam, behavior: 'smooth'})}} className={ this.state.contactLogoPeaceMounted ? 'outside hex6' : 'outside hex6-before'}>
        <div className="inside">
          <div className="double-inside pos6">
            {engSrbParam === 'eng' ? 'Contact' : 'Kontakt' }
          </div>
        </div>
      </div>
      :
      null
      }
      <div className='starting-burger-hexagon' style={{zIndex: this.state.triggerBurgerModal ? 150 : 0 ,position:'fixed' , opacity: this.state.triggerBurgerModal ? 1 : 0 , width:window.innerWidth - window.innerWidth/10 , height:window.innerWidth - window.innerWidth/10 , left:window.innerWidth/20 , top:window.innerWidth/2.5}}>
        <div className="starting-burger-hexagon-inside burger">
          <AiOutlineClose onClick={()=>{ document.body.style.overflowY= 'scroll'; this.setState({ triggerBurgerModal: false }) }} className='burger-elements' style={{ position:'absolute' , left:'calc(50% - 10px)' , top:'5%' , width:'20px' ,color:'white', height:'20px' ,filter: 'drop-shadow(1px 1px rgba(0,0,0,1))' , backgroundColor:'white' , textAlign:'center'}}/>
          <div style={{position:'absolute' , left:'25%' , top:'25%' , width:'50%' , height:'50%' , textAlign:'center' ,display:'flex' ,alignItems:'center' , justifyContent:'center' , flexDirection:'column'}}>
            <div onClick={()=> { document.body.style.overflowY= 'scroll'; this.setState({ triggerBurgerModal: false }); window.scrollTo({top: 0, behavior: 'smooth'})}} className='burger-elements' style={{ flex:1 ,fontSize:20}}>{engSrbParam === 'eng' ? 'Home' : 'Početna' }</div>
            <div onClick={()=> { document.body.style.overflowY= 'scroll'; this.setState({ triggerBurgerModal: false }); window.scrollTo({top: aboutBigTextYoffsetParam, behavior: 'smooth'})}} className='burger-elements' style={{color:'white', flex:1,fontSize:20}}>{engSrbParam === 'eng' ? 'About' : 'O nama' }</div>
            <div onClick={()=> { document.body.style.overflowY= 'scroll'; this.setState({ triggerBurgerModal: false }); window.scrollTo({top: galleryHeadlineYoffsetParam, behavior: 'smooth'})}} className='burger-elements' style={{color:'white', flex:1,fontSize:20}}>{engSrbParam === 'eng' ? 'Gallery' : 'Galerija' }</div>
            <div onClick={()=> { document.body.style.overflowY= 'scroll'; this.setState({ triggerBurgerModal: false }); window.scrollTo({top: videoGalleryYoffsetParam, behavior: 'smooth'})}} className='burger-elements' style={{color:'white', flex:1,fontSize:20}}>Videos</div>
            <div onClick={()=> { document.body.style.overflowY= 'scroll'; this.setState({ triggerBurgerModal: false }); window.scrollTo({top: contactYoffsetParam, behavior: 'smooth'})}} className='burger-elements' style={{color:'white', flex:1,fontSize:20}}>{engSrbParam === 'eng' ? 'Contact' : 'Kontakt' }</div>
            <div onClick={()=> { this.changeLanguage( engSrbParam ); document.body.style.overflowY= 'scroll'; this.setState({ triggerBurgerModal: false })}} className='burger-elements' style={{color:'white', flex:1,fontSize:20}}>Eng/Srb</div>
          </div>
        </div>
      </div>



    </div>
  );
}
}
const mapStateToProps = (state) => {
  const aboutBigTextYoffsetParam = state.aboutBigTextYoffsetParam;
  const aboutThreeContainersYoffsetParam = state.aboutThreeContainersYoffsetParam;
  const galleryHeadlineYoffsetParam = state.galleryHeadlineYoffsetParam;
  const aboutTeamYoffsetParam = state.aboutTeamYoffsetParam;
  const showVideoGalleryParam = state.showVideoGalleryParam;
  const videoGalleryYoffsetParam = state.videoGalleryYoffsetParam;
  const contactYoffsetParam = state.contactYoffsetParam;
  const engSrbParam = state.engSrbParam;

  return {
    aboutBigTextYoffsetParam,
    aboutThreeContainersYoffsetParam,
    galleryHeadlineYoffsetParam,
    aboutTeamYoffsetParam,
    showVideoGalleryParam,
    videoGalleryYoffsetParam,
    contactYoffsetParam,
    engSrbParam
  }
}
export default connect(mapStateToProps)(Navigation);
