export const TEST_ACTION = 'TEST_ACTION';
export const ABOUTBIGTEXTYOFFSET_ACTION = 'ABOUTBIGTEXTYOFFSET_ACTION';
export const SHOWABOUTBIGTEXT_ACTION ='SHOWABOUTBIGTEXT_ACTION';
export const ABOUTTHREECONTAINERSYOFFSET_ACTION = 'ABOUTTHREECONTAINERSYOFFSET_ACTION';
export const SHOWABOUTTHREECONTAINERS_ACTION = 'SHOWABOUTTHREECONTAINERS_ACTION';
export const GALLERYHEADLINEYOFFSET_ACTION = 'GALLERYHEADLINEYOFFSET_ACTION';
export const SHOWAGALLERYHEADLINE_ACTION = 'SHOWAGALLERYHEADLINE_ACTION';
export const ASYNCMARGINTOP_ACTION = 'ASYNCMARGINTOP_ACTION';
export const GALLERYPICKEDIMAGE_ACTION = 'GALLERYPICKEDIMAGE_ACTION';
export const SHOWGALLERYIMAGES_ACTION = 'SHOWGALLERYIMAGES_ACTION';
export const ABOUTTEAMYOFFSET_ACTION = 'ABOUTTEAMYOFFSET_ACTION';
export const SHOWABOUTTEAM_ACTION = 'SHOWABOUTTEAM_ACTION';
export const VIDEOGALLERYYOFFSET_ACTION = 'VIDEOGALLERYYOFFSET_ACTION';
export const SHOWVIDEOGALLERY_ACTION = 'SHOWVIDEOGALLERY_ACTION';
export const CONTACTYOFFSET_ACTION = 'CONTACTYOFFSET_ACTION';
export const OPENBIGPICTURE_ACTION = 'OPENBIGPICTURE_ACTION';
export const ENGSRB_ACTION = 'ENGSRB_ACTION';
