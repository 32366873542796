import '../../css/gallery.css';
import '../../css/videoGallery.css';
import {connect} from 'react-redux';
import React from 'react';
import { videoGalleryYoffsetAction } from '../../actions/main';
import { AiOutlineLeft , AiOutlineRight } from "react-icons/ai";
import POSTER1 from '../../img/poster1.webp';
import POSTER2 from '../../img/poster2.webp';
import POSTER3 from '../../img/poster3.webp';
import POSTER4 from '../../img/poster4.webp';
import POSTER5 from '../../img/poster5.webp';
import POSTER6 from '../../img/poster6.webp';
import POSTER7 from '../../img/poster7.webp';
import POSTER8 from '../../img/poster8.webp';
import POSTER9 from '../../img/poster9.webp';
import POSTER10 from '../../img/poster10.webp';
import POSTER11 from '../../img/poster11.webp';

class VideoGallery extends React.Component {
  constructor(props) {
   super(props);
   this.wrapper = React.createRef();
   this.videoRef = React.createRef();
   this.state = {
     windowWidth: window.innerWidth,
     windowHeight: window.innerHeight,
     videoActivated: 6

   }
 }
 updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    this.props.dispatch(videoGalleryYoffsetAction( this.wrapper ? this.wrapper.current.offsetTop : null ));
  }
 componentDidMount() {
   window.addEventListener('resize', this.updateDimensions);
   this.props.dispatch(videoGalleryYoffsetAction( this.wrapper ? this.wrapper.current.offsetTop : null ));
 }
 componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
 }
 handleClasses( value ) {
   this.setState({ videoActivated: value })
   if(!this.vidRef1.paused && value !==1) this.vidRef1.pause();
   if(!this.vidRef2.paused && value !==2) this.vidRef2.pause();
   if(!this.vidRef3.paused && value !==3) this.vidRef3.pause();
   if(!this.vidRef4.paused && value !==4) this.vidRef4.pause();
   if(!this.vidRef5.paused && value !==5) this.vidRef5.pause();
   if(!this.vidRef6.paused && value !==6) this.vidRef6.pause();
   if(!this.vidRef7.paused && value !==7) this.vidRef7.pause();
   if(!this.vidRef8.paused && value !==8) this.vidRef8.pause();
   if(!this.vidRef9.paused && value !==9) this.vidRef9.pause();
   if(!this.vidRef10.paused && value !==10) this.vidRef10.pause();
   if(!this.vidRef11.paused && value !==11) this.vidRef11.pause();
   console.log( value , 'ovde sam')
 }
 render(){
   const { showVideoGalleryParam , engSrbParam } = this.props;
   return (
     <div  style={{ display: 'flex' , flexDirection:'column' , justifyContent: 'center' , background:'black' ,width: window.innerWidth , zIndex:100}}>
       <div ref={this.wrapper} className='gallery-headline-border-left' style={{marginLeft:showVideoGalleryParam ? 0 : '-40%' ,transition: 'all 1s ease-out', width:'30%' }}/>
       { engSrbParam === 'eng' ?
         <div className='gallery-headline-text' style={{opacity:  showVideoGalleryParam ? 1 : 0 , transition: 'all 1s ease-out'}}>
           <p className='gallery-headline-char-wrapper'>V</p>
           <p className='gallery-headline-char-wrapper'>i</p>
           <p className='gallery-headline-char-wrapper'>d</p>
           <p className='gallery-headline-char-wrapper'>e</p>
           <p style={{ marginRight:'20px' }} className='gallery-headline-char-wrapper'>o</p>
           <p className='gallery-headline-char-wrapper'>G</p>
           <p className='gallery-headline-char-wrapper'>a</p>
           <p className='gallery-headline-char-wrapper'>l</p>
           <p className='gallery-headline-char-wrapper'>l</p>
           <p className='gallery-headline-char-wrapper'>e</p>
           <p className='gallery-headline-char-wrapper'>r</p>
           <p className='gallery-headline-char-wrapper'>y</p>
         </div>
         :
         <div className='gallery-headline-text' style={{opacity:  showVideoGalleryParam ? 1 : 0 , transition: 'all 1s ease-out'}}>
           <p className='gallery-headline-char-wrapper'>V</p>
           <p className='gallery-headline-char-wrapper'>i</p>
           <p className='gallery-headline-char-wrapper'>d</p>
           <p className='gallery-headline-char-wrapper'>e</p>
           <p style={{ marginRight:'20px' }} className='gallery-headline-char-wrapper'>o</p>
           <p className='gallery-headline-char-wrapper'>G</p>
           <p className='gallery-headline-char-wrapper'>a</p>
           <p className='gallery-headline-char-wrapper'>l</p>
           <p className='gallery-headline-char-wrapper'>e</p>
           <p className='gallery-headline-char-wrapper'>r</p>
           <p className='gallery-headline-char-wrapper'>i</p>
           <p className='gallery-headline-char-wrapper'>j</p>
           <p className='gallery-headline-char-wrapper'>a</p>
         </div>
       }

       <div  className='gallery-headline-border-right' style={{marginLeft:showVideoGalleryParam ? '70%': '110%' , transition: 'all 1s ease-out' , width:'30%' , marginBottom:window.innerHeight/10}}/>
       <div  ref={this.wrapper} style={{  position:'relative' , height:window.innerHeight , width:window.innerWidth}}>
         <div className='video-wrapper'>
          <div style={{position:'relative'}}
          className={
            this.state.videoActivated === 1 ? 'video-item-active':
            this.state.videoActivated===2 ? 'video-item video-item-one-difference':
            this.state.videoActivated===3 ? 'video-item video-item-two-difference':
            this.state.videoActivated===4 ? 'video-item video-item-three-difference':
            this.state.videoActivated=== 5 ? 'video-item video-item-four-difference':
            this.state.videoActivated===6 ? 'video-item video-item-five-difference':
            this.state.videoActivated===7 ? 'video-item video-item-six-difference':
            this.state.videoActivated===8 ? 'video-item video-item-seven-difference':
            this.state.videoActivated===9 ? 'video-item video-item-eight-difference':
            this.state.videoActivated===10 ? 'video-item video-item-nine-difference':
            'video-item video-item-ten-difference'
          }
          >
            <video poster={POSTER1} onClick={()=>{ this.handleClasses(1) }} ref={elem => this.vidRef1 = elem} className='inner-video-wrapper'  controls= { this.state.videoActivated === 1 ? true : false } src={require("../../videos/video1.MOV")} type="video/mp4">
            </video>
            <AiOutlineRight className='ai-outline-right' size="20%" color="white" onClick={()=>{ this.handleClasses(2) }} style={{opacity: this.state.videoActivated === 1 ? 1 : 0}}/>
          </div>
          <div style={{position:'relative'}}
          className={
            this.state.videoActivated === 2 ? 'video-item-active':
            this.state.videoActivated===1 || this.state.videoActivated===3 ? 'video-item video-item-one-difference':
            this.state.videoActivated===4 ? 'video-item video-item-two-difference':
            this.state.videoActivated===5 ? 'video-item video-item-three-difference':
            this.state.videoActivated === 6 ? 'video-item video-item-four-difference':
            this.state.videoActivated===7 ? 'video-item video-item-five-difference':
            this.state.videoActivated===8 ? 'video-item video-item-six-difference':
            this.state.videoActivated===9 ? 'video-item video-item-seven-difference':
            this.state.videoActivated === 10 ? 'video-item video-item-eight-difference':
            'video-item video-item-nine-difference'
          }>
            <video poster={POSTER2} onClick={()=>{ this.handleClasses(2) }} ref={elem => this.vidRef2 = elem} className='inner-video-wrapper' controls= { this.state.videoActivated === 2 ? true : false }  src={require("../../videos/video2.mov")} type="video/mp4">
            </video>
            <AiOutlineLeft className='ai-outline-left' size="20%" color="white" onClick={()=>{ this.handleClasses(1) }} style={{opacity: this.state.videoActivated === 2 ? 1 : 0}}/>
            <AiOutlineRight className='ai-outline-right' size="20%" color="white" onClick={()=>{ this.handleClasses(3) }} style={{opacity: this.state.videoActivated === 2 ? 1 : 0}}/>
          </div>
          <div style={{position:'relative'}}
          className={
            this.state.videoActivated === 3 ? 'video-item-active':
            this.state.videoActivated===2 || this.state.videoActivated===4 ? 'video-item video-item-one-difference':
            this.state.videoActivated===1 || this.state.videoActivated===5 ? 'video-item video-item-two-difference':
            this.state.videoActivated===6 ? 'video-item video-item-three-difference':
            this.state.videoActivated === 7 ? 'video-item video-item-four-difference':
            this.state.videoActivated===8 ? 'video-item video-item-five-difference':
            this.state.videoActivated===9 ? 'video-item video-item-six-difference':
            this.state.videoActivated===10 ? 'video-item video-item-seven-difference':
            'video-item video-item-eight-difference'
          }>
            <video poster={POSTER3} onClick={()=>{ this.handleClasses(3) }} ref={elem => this.vidRef3 = elem} className='inner-video-wrapper' controls= { this.state.videoActivated === 3 ? true : false }  src={require("../../videos/video3.MOV")} type="video/mp4">
            </video>
            <AiOutlineLeft className='ai-outline-left' size="20%" color="white" onClick={()=>{ this.handleClasses(2) }} style={{opacity: this.state.videoActivated === 3 ? 1 : 0}}/>
            <AiOutlineRight className='ai-outline-right' size="20%" color="white" onClick={()=>{ this.handleClasses(4) }} style={{opacity: this.state.videoActivated === 3 ? 1 : 0}}/>
          </div>
          <div style={{position:'relative'}}
          className={
            this.state.videoActivated === 4 ? 'video-item-active':
            this.state.videoActivated===3 || this.state.videoActivated===5 ? 'video-item video-item-one-difference':
            this.state.videoActivated===2 || this.state.videoActivated===6 ? 'video-item video-item-two-difference':
            this.state.videoActivated===1 || this.state.videoActivated===7 ? 'video-item video-item-three-difference':
            this.state.videoActivated===8 ? 'video-item video-item-four-difference':
            this.state.videoActivated===9 ? 'video-item video-item-five-difference':
            this.state.videoActivated===10 ? 'video-item video-item-six-difference':
            'video-item video-item-seven-difference'
          }>
            <video poster={POSTER4} onClick={()=>{ this.handleClasses(4) }} ref={elem => this.vidRef4 = elem} className='inner-video-wrapper' controls= { this.state.videoActivated === 4 ? true : false }  src={require("../../videos/video4.MOV")} type="video/mp4">
            </video>
            <AiOutlineLeft className='ai-outline-left' size="20%" color="white" onClick={()=>{ this.handleClasses(3) }} style={{opacity: this.state.videoActivated === 4 ? 1 : 0}}/>
            <AiOutlineRight className='ai-outline-right' size="20%" color="white" onClick={()=>{ this.handleClasses(5) }} style={{opacity: this.state.videoActivated === 4 ? 1 : 0}}/>
          </div>
          <div style={{position:'relative'}}
          className={
            this.state.videoActivated === 5 ? 'video-item-active':
            this.state.videoActivated===4 || this.state.videoActivated===6 ? 'video-item video-item-one-difference':
            this.state.videoActivated===3 || this.state.videoActivated===7 ? 'video-item video-item-two-difference':
            this.state.videoActivated===2 || this.state.videoActivated===8 ? 'video-item video-item-three-difference':
            this.state.videoActivated===1 || this.state.videoActivated===9 ? 'video-item video-item-four-difference':
            this.state.videoActivated===10 ? 'video-item video-item-five-difference':
            'video-item video-item-six-difference'
          }>
            <video poster={POSTER5} onClick={()=>{ this.handleClasses(5) }} ref={elem => this.vidRef5 = elem} className='inner-video-wrapper' controls= { this.state.videoActivated === 5 ? true : false }  src={require("../../videos/video5.MOV")} type="video/mp4">
            </video>
            <AiOutlineLeft className='ai-outline-left' size="20%" color="white" onClick={()=>{ this.handleClasses(4) }} style={{opacity: this.state.videoActivated === 5 ? 1 : 0}}/>
            <AiOutlineRight className='ai-outline-right' size="20%" color="white" onClick={()=>{ this.handleClasses(6) }} style={{opacity: this.state.videoActivated === 5 ? 1 : 0}}/>
          </div>
          <div style={{position:'relative'}}
          className={
            this.state.videoActivated === 6 ? 'video-item-active':
            this.state.videoActivated===5 || this.state.videoActivated===7 ? 'video-item video-item-one-difference':
            this.state.videoActivated===4 || this.state.videoActivated===8 ? 'video-item video-item-two-difference':
            this.state.videoActivated===3 || this.state.videoActivated===9 ? 'video-item video-item-three-difference':
            this.state.videoActivated===2 || this.state.videoActivated===10 ? 'video-item video-item-four-difference':
            'video-item video-item-five-difference'
          }>
            <video poster={POSTER6} onClick={()=>{ this.handleClasses(6) }} ref={elem => this.vidRef6 = elem} className='inner-video-wrapper' controls= { this.state.videoActivated === 6 ? true : false }  src={require("../../videos/video6.MOV")} type="video/mp4">
            </video>
            <AiOutlineLeft className='ai-outline-left' size="20%" color="white" onClick={()=>{ this.handleClasses(5) }} style={{opacity: this.state.videoActivated === 6 ? 1 : 0}}/>
            <AiOutlineRight className='ai-outline-right' size="20%" color="white" onClick={()=>{ this.handleClasses(7) }} style={{opacity: this.state.videoActivated === 6 ? 1 : 0}}/>
          </div>
          <div style={{position:'relative'}}
          className={
            this.state.videoActivated === 7 ? 'video-item-active':
            this.state.videoActivated===6 || this.state.videoActivated===8 ? 'video-item video-item-one-difference':
            this.state.videoActivated===5 || this.state.videoActivated===9 ? 'video-item video-item-two-difference':
            this.state.videoActivated===4 || this.state.videoActivated===10 ? 'video-item video-item-three-difference':
            this.state.videoActivated===3 || this.state.videoActivated===11 ? 'video-item video-item-four-difference':
            this.state.videoActivated===2 ? 'video-item video-item-five-difference':
            'video-item video-item-six-difference'
          }>
            <video poster={POSTER7} onClick={()=>{ this.handleClasses(7) }} ref={elem => this.vidRef7 = elem} className='inner-video-wrapper' controls= { this.state.videoActivated === 7 ? true : false }  src={require("../../videos/video7.MOV")} type="video/mp4">
            </video>
            <AiOutlineLeft className='ai-outline-left' size="20%" color="white" onClick={()=>{ this.handleClasses(6) }} style={{opacity: this.state.videoActivated === 7 ? 1 : 0}}/>
            <AiOutlineRight className='ai-outline-right' size="20%" color="white" onClick={()=>{ this.handleClasses(8) }} style={{opacity: this.state.videoActivated === 7 ? 1 : 0}}/>
          </div>
          <div style={{position:'relative'}}
          className={
            this.state.videoActivated === 8 ? 'video-item-active':
            this.state.videoActivated===7 || this.state.videoActivated===9 ? 'video-item video-item-one-difference':
            this.state.videoActivated===6 || this.state.videoActivated===10 ? 'video-item video-item-two-difference':
            this.state.videoActivated===5 || this.state.videoActivated===11 ? 'video-item video-item-three-difference':
            this.state.videoActivated===4 ? 'video-item video-item-four-difference':
            this.state.videoActivated===3 ? 'video-item video-item-five-difference':
            this.state.videoActivated===2 ? 'video-item video-item-six-difference':
            'video-item video-item-seven-difference'
          }>
            <video poster={POSTER8} onClick={()=>{ this.handleClasses(8) }} ref={elem => this.vidRef8 = elem} className='inner-video-wrapper' controls= { this.state.videoActivated === 8 ? true : false }  src={require("../../videos/video8.MOV")} type="video/mp4">
            </video>
            <AiOutlineLeft className='ai-outline-left' size="20%" color="white" onClick={()=>{ this.handleClasses(7) }} style={{opacity: this.state.videoActivated === 8 ? 1 : 0}}/>
            <AiOutlineRight className='ai-outline-right' size="20%" color="white" onClick={()=>{ this.handleClasses(9) }} style={{opacity: this.state.videoActivated === 8 ? 1 : 0}}/>
          </div>
          <div style={{position:'relative'}}
          className={
            this.state.videoActivated === 9 ? 'video-item-active':
            this.state.videoActivated===8 || this.state.videoActivated===10 ? 'video-item video-item-one-difference':
            this.state.videoActivated===7 || this.state.videoActivated===11 ? 'video-item video-item-two-difference':
            this.state.videoActivated===6 ? 'video-item video-item-three-difference':
            this.state.videoActivated===5 ? 'video-item video-item-four-difference':
            this.state.videoActivated===4 ? 'video-item video-item-five-difference':
            this.state.videoActivated===3 ? 'video-item video-item-six-difference':
            this.state.videoActivated===2 ? 'video-item video-item-seven-difference':
            'video-item video-item-eight-difference'
          }>
            <video poster={POSTER9} onClick={()=>{ this.handleClasses(9) }} ref={elem => this.vidRef9 = elem} className='inner-video-wrapper' controls= { this.state.videoActivated === 9 ? true : false }  src={require("../../videos/video9.MOV")} type="video/mp4">
            </video>
            <AiOutlineLeft className='ai-outline-left' size="20%" color="white" onClick={()=>{ this.handleClasses(8) }} style={{opacity: this.state.videoActivated === 9 ? 1 : 0}}/>
            <AiOutlineRight className='ai-outline-right' size="20%" color="white" onClick={()=>{ this.handleClasses(10) }} style={{opacity: this.state.videoActivated === 9 ? 1 : 0}}/>
          </div>
          <div style={{position:'relative'}}
          className={
            this.state.videoActivated === 10 ? 'video-item-active':
            this.state.videoActivated===9 || this.state.videoActivated===11 ? 'video-item video-item-one-difference':
            this.state.videoActivated===8 ? 'video-item video-item-two-difference':
            this.state.videoActivated===7 ? 'video-item video-item-three-difference':
            this.state.videoActivated===6 ? 'video-item video-item-four-difference':
            this.state.videoActivated===5 ? 'video-item video-item-five-difference':
            this.state.videoActivated===4 ? 'video-item video-item-six-difference':
            this.state.videoActivated===3 ? 'video-item video-item-seven-difference':
            this.state.videoActivated===2 ? 'video-item video-item-eight-difference':
            'video-item video-item-nine-difference'
          }>
            <video poster={POSTER10} onClick={()=>{ this.handleClasses(10) }} ref={elem => this.vidRef10 = elem} className='inner-video-wrapper' controls= { this.state.videoActivated === 10 ? true : false }  src={require("../../videos/video10.MOV")} type="video/mp4">
            </video>
            <AiOutlineLeft className='ai-outline-left' size="20%" color="white" onClick={()=>{ this.handleClasses(9) }} style={{opacity: this.state.videoActivated === 10 ? 1 : 0}}/>
            <AiOutlineRight className='ai-outline-right' size="20%" color="white" onClick={()=>{ this.handleClasses(11) }} style={{opacity: this.state.videoActivated === 10 ? 1 : 0}}/>
          </div>
          <div style={{position:'relative'}}
          className={
            this.state.videoActivated === 11 ? 'video-item-active':
            this.state.videoActivated===10 ? 'video-item video-item-one-difference':
            this.state.videoActivated===9 ? 'video-item video-item-two-difference':
            this.state.videoActivated===8 ? 'video-item video-item-three-difference':
            this.state.videoActivated===7 ? 'video-item video-item-four-difference':
            this.state.videoActivated===6 ? 'video-item video-item-five-difference':
            this.state.videoActivated===5 ? 'video-item video-item-six-difference':
            this.state.videoActivated===4 ? 'video-item video-item-seven-difference':
            this.state.videoActivated===3 ? 'video-item video-item-eight-difference':
            this.state.videoActivated===2 ? 'video-item video-item-nine-difference':
            'video-item video-item-ten-difference'
          }>
            <video poster={POSTER11} onClick={()=>{ this.handleClasses(11) }} ref={elem => this.vidRef11 = elem} className='inner-video-wrapper' controls= { this.state.videoActivated === 11 ? true : false }  src={require("../../videos/video11.MOV")} type="video/mp4">
            </video>
            <AiOutlineLeft className='ai-outline-left' size="20%" color="white" onClick={()=>{ this.handleClasses(10) }} style={{opacity: this.state.videoActivated === 11 ? 1 : 0}}/>
          </div>
         </div>
       </div>
     </div>
   );
 }

}

const mapStateToProps = (state) => {
  const showVideoGalleryParam = state.showVideoGalleryParam;
  const engSrbParam = state.engSrbParam;

  return {
    showVideoGalleryParam,
    engSrbParam
  }
}
export default connect(mapStateToProps)(VideoGallery);
