import '../../css/gallery.css'
import {connect} from 'react-redux';
import React from 'react';
import { galleryPickedImageAction , openBigPictureAction } from '../../actions/main';
/* eslint-disable jsx-a11y/anchor-is-valid */

class GalleryHexagonSection extends React.Component {
  constructor(props) {
   super(props);
   this.state = {
     windowWidth: window.innerWidth,
     windowHeight: window.innerHeight,

   }
 }
 updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
 }
 componentDidMount() {
   window.addEventListener('resize', this.updateDimensions);
 }
 componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
 }
 render(){
   const { showGalleryImagesParam , engSrbParam} = this.props;
   return (
     <div style={{opacity: showGalleryImagesParam ? 1 : 0 , transition: 'all 1s ease-out' , flex:1 , width:'48%' , marginLeft:'1%'}}>
     <ul  className="hexagoncomb">
     		<li className='hexagon-wrapper'>
     			<div className="cell">
     				<a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/jkresized.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
     					<img src={require("../../img/thumbnails/jkthumb.jpg")} alt=""/>
     					<span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
     					<span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
     				</a>
     			</div>
     		</li>
     		<li className='hexagon-wrapper'>
     			<div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/gallery1.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
     					<img src={require("../../img/thumbnails/gallery1.jpg")} alt=""/>
     					<span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
     					<span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
     				</a>
     			</div>
     		</li>
     		<li className='hexagon-wrapper'>
     			<div className="cell">
     				<a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/gallery2.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
     					<img src={require("../../img/thumbnails/gallery2.jpg")} alt=""/>
     					<span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
     					<span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
     				</a>
     			</div>
     		</li>
     		<li className='hexagon-wrapper'>
     			<div className="cell">
     				<a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/gallery3.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
     					<img src={require("../../img/thumbnails/gallery3.jpg")} alt=""/>
     					<span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
     					<span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
     				</a>
     			</div>
     		</li>
     		<li className='hexagon-wrapper'>
     			<div className="cell">
     				<a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/gallery4.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
     					<img src={require("../../img/thumbnails/gallery4.jpg")} alt=""/>
     					<span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
     					<span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
     				</a>
     			</div>
     		</li>
     		<li className='hexagon-wrapper'>
     			<div className="cell">
     				<a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/gallery5.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
     					<img src={require("../../img/thumbnails/gallery5.jpg")} alt=""/>
     					<span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
     					<span className="text">by Steyra</span>
     				</a>
     			</div>
     		</li>
     		<li className='hexagon-wrapper'>
     			<div className="cell">
     				<a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/gallery6.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
     					<img src={require("../../img/thumbnails/gallery6.jpg")} alt=""/>
     					<span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
     					<span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
     				</a>
     			</div>
     		</li>
     		<li className='hexagon-wrapper'>
     			<div className="cell">
     				<a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/gallery7.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
     					<img src={require("../../img/thumbnails/gallery7.jpg")} alt=""/>
     					<span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
     					<span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
     				</a>
     			</div>
     		</li>
     		<li className='hexagon-wrapper'>
     			<div className="cell">
     				<a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/gallery8.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
     					<img src={require("../../img/thumbnails/gallery8.jpg")} alt=""/>
     					<span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
     					<span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
     				</a>
     			</div>
     		</li>
     		<li className='hexagon-wrapper'>
     			<div className="cell">
     				<a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/gallery9.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
     					<img src={require("../../img/thumbnails/gallery9.jpg")} alt=""/>
     					<span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
     					<span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
     				</a>
     			</div>
     		</li>
     		<li className='hexagon-wrapper'>
     			<div className="cell">
     				<a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/gallery10.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
     					<img src={require("../../img/thumbnails/gallery10.jpg")} alt=""/>
     					<span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
     					<span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
     				</a>
     			</div>
     		</li>
        <li className='hexagon-wrapper'>
     			<div className="cell">
     				<a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/gallery11.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
     					<img src={require("../../img/thumbnails/gallery11.jpg")} alt=""/>
     					<span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
     					<span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
     				</a>
     			</div>
     		</li>
     		<li className='hexagon-wrapper'>
     			<div className="cell">
     				<a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/gallery12.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
     					<img src={require("../../img/thumbnails/gallery12.jpg")} alt=""/>
     					<span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
     					<span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
     				</a>
     			</div>
     		</li>
     		<li className='hexagon-wrapper'>
     			<div className="cell">
     				<a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/gallery13.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
     					<img src={require("../../img/thumbnails/gallery13.jpg")} alt=""/>
     					<span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
     					<span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
     				</a>
     			</div>
     		</li>
     		<li className='hexagon-wrapper'>
     			<div className="cell">
     				<a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/gallery14.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
     					<img src={require("../../img/thumbnails/gallery14.jpg")} alt=""/>
     					<span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
     					<span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
     				</a>
     			</div>
     		</li>
     		<li className='hexagon-wrapper'>
     			<div className="cell">
     				<a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/gallery15.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
     					<img src={require("../../img/thumbnails/gallery15.jpg")} alt=""/>
     					<span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
     					<span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
     				</a>
     			</div>
     		</li>
     		<li className='hexagon-wrapper'>
     			<div className="cell">
     				<a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/gallery16.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
     					<img src={require("../../img/thumbnails/gallery16.jpg")} alt=""/>
     					<span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
     					<span className="text">by Steyra</span>
     				</a>
     			</div>
     		</li>
     		<li className='hexagon-wrapper'>
     			<div className="cell">
     				<a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/gallery17.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
     					<img src={require("../../img/thumbnails/gallery17.jpg")} alt=""/>
     					<span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
     					<span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
     				</a>
     			</div>
     		</li>
     		<li className='hexagon-wrapper'>
     			<div className="cell">
     				<a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/cat1.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
     					<img src={require("../../img/thumbnails/cat1.jpg")} alt=""/>
     					<span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
     					<span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
     				</a>
     			</div>
     		</li>
     		<li className='hexagon-wrapper'>
     			<div className="cell">
     				<a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/cat2.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
     					<img src={require("../../img/thumbnails/cat2.jpg")} alt=""/>
     					<span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
     					<span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
     				</a>
     			</div>
     		</li>
     		<li className='hexagon-wrapper'>
     			<div className="cell">
     				<a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/cat3.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
     					<img src={require("../../img/thumbnails/cat3.jpg")} alt=""/>
     					<span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
     					<span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
     				</a>
     			</div>
     		</li>
        <li className='hexagon-wrapper'>
     			<div className="cell">
     				<a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/cat4.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
     					<img src={require("../../img/thumbnails/cat4.jpg")} alt=""/>
     					<span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
     					<span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
     				</a>
     			</div>
     		</li>
     		<li className='hexagon-wrapper'>
     			<div className="cell">
     				<a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/cat5.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
     					<img src={require("../../img/thumbnails/cat5.jpg")} alt=""/>
     					<span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
     					<span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
     				</a>
     			</div>
     		</li>
        <li className='hexagon-wrapper'>
     			<div className="cell">
     				<a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/jk2.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
     					<img src={require("../../img/thumbnails/jk2.jpg")} alt=""/>
     					<span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
     					<span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
     				</a>
     			</div>
     		</li>
        <li className='hexagon-wrapper'>
     			<div className="cell">
     				<a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/jk3.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
     					<img src={require("../../img/thumbnails/jk3.jpg")} alt=""/>
     					<span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
     					<span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
     				</a>
     			</div>
     		</li>

        <li className='hexagon-wrapper'>
     			<div className="cell">
     				<a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/npc26.jpeg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
     					<img src={require("../../img/IMG_66154.jpg")} alt=""/>
     					<span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
     					<span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
     				</a>
     			</div>
     		</li>

        <li className='hexagon-wrapper'>
     			<div className="cell">
     				<a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/npc1.JPG")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
     					<img src={require("../../img/imageedit_29_9371599643.jpg")} alt=""/>
     					<span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
     					<span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
     				</a>
     			</div>
     		</li>

        <li className='hexagon-wrapper'>
          <div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/npc2.JPG")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
              <img src={require("../../img/image_50416129small.jpg")} alt=""/>
              <span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
              <span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
            </a>
          </div>
        </li>

        <li className='hexagon-wrapper'>
          <div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/npc3.JPG")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
              <img src={require("../../img/image_50439681small.jpg")} alt=""/>
              <span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
              <span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
            </a>
          </div>
        </li>

        <li className='hexagon-wrapper'>
          <div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/npc4.JPG")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
              <img src={require("../../img/image_50447873small.jpg")} alt=""/>
              <span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
              <span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
            </a>
          </div>
        </li>

        <li className='hexagon-wrapper'>
          <div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/npc5.JPG")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
              <img src={require("../../img/image_50455041small.jpg")} alt=""/>
              <span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
              <span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
            </a>
          </div>
        </li>

        <li className='hexagon-wrapper'>
          <div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/npc6.JPG")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
              <img src={require("../../img/image_50778369small.jpg")} alt=""/>
              <span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
              <span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
            </a>
          </div>
        </li>

        <li className='hexagon-wrapper'>
          <div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/npc7.JPG")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
              <img src={require("../../img/image_67227905 2small.jpg")} alt=""/>
              <span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
              <span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
            </a>
          </div>
        </li>

        <li className='hexagon-wrapper'>
          <div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/g1.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
              <img src={require("../../img/gallerybigptcs/g1.jpg")} alt=""/>
              <span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
              <span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
            </a>
          </div>
        </li>

        <li className='hexagon-wrapper'>
          <div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/npc9.JPG")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
              <img src={require("../../img/image_123650291 2small.jpg")} alt=""/>
              <span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
              <span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
            </a>
          </div>
        </li>

        <li className='hexagon-wrapper'>
          <div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/npc10.JPG")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
              <img src={require("../../img/image_123650291 3small.jpg")} alt=""/>
              <span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
              <span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
            </a>
          </div>
        </li>

        <li className='hexagon-wrapper'>
          <div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/npc11.JPG")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
              <img src={require("../../img/image_123650291 4small.jpg")} alt=""/>
              <span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
              <span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
            </a>
          </div>
        </li>

        <li className='hexagon-wrapper'>
          <div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/npc12.JPG")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
              <img src={require("../../img/image_123650291 5small.jpg")} alt=""/>
              <span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
              <span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
            </a>
          </div>
        </li>

        <li className='hexagon-wrapper'>
          <div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/npc13.JPG")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
              <img src={require("../../img/image_123650291 6small.jpg")} alt=""/>
              <span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
              <span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
            </a>
          </div>
        </li>

        <li className='hexagon-wrapper'>
          <div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/npc14.JPG")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
              <img src={require("../../img/image_123650291 7small.jpg")} alt=""/>
              <span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
              <span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
            </a>
          </div>
        </li>

        <li className='hexagon-wrapper'>
          <div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/npc15.JPG")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
              <img src={require("../../img/image_123650291 8small.jpg")} alt=""/>
              <span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
              <span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
            </a>
          </div>
        </li>

        <li className='hexagon-wrapper'>
          <div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/npc16.JPG")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
              <img src={require("../../img/image_123650291 9small.jpg")} alt=""/>
              <span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
              <span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
            </a>
          </div>
        </li>

        <li className='hexagon-wrapper'>
          <div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/npc17.JPG")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
              <img src={require("../../img/image_123650291 10small.jpg")} alt=""/>
              <span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
              <span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
            </a>
          </div>
        </li>

        <li className='hexagon-wrapper'>
          <div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/npc18.JPG")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
              <img src={require("../../img/image_123650291 11small.jpg")} alt=""/>
              <span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
              <span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
            </a>
          </div>
        </li>

        <li className='hexagon-wrapper'>
          <div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/npc19.JPG")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
              <img src={require("../../img/image_123650291 12small.jpg")} alt=""/>
              <span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
              <span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
            </a>
          </div>
        </li>

        <li className='hexagon-wrapper'>
          <div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/npc20.JPG")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
              <img src={require("../../img/image_123650291small.jpg")} alt=""/>
              <span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
              <span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
            </a>
          </div>
        </li>

        <li className='hexagon-wrapper'>
          <div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/npc21.jpeg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
              <img src={require("../../img/IMG_1941small.jpg")} alt=""/>
              <span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
              <span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
            </a>
          </div>
        </li>

        <li className='hexagon-wrapper'>
          <div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/npc22.jpeg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
              <img src={require("../../img/IMG_5919small.jpg")} alt=""/>
              <span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
              <span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
            </a>
          </div>
        </li>

        <li className='hexagon-wrapper'>
          <div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/npc23.jpeg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
              <img src={require("../../img/IMG_5926small.jpg")} alt=""/>
              <span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
              <span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
            </a>
          </div>
        </li>

        <li className='hexagon-wrapper'>
          <div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/npc24.jpeg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
              <img src={require("../../img/IMG_5927small.jpg")} alt=""/>
              <span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
              <span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
            </a>
          </div>
        </li>

        <li className='hexagon-wrapper'>
          <div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/g8.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
              <img src={require("../../img/gallerybigptcs/g8.jpg")} alt=""/>
              <span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
              <span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
            </a>
          </div>
        </li>

        <li className='hexagon-wrapper'>
          <div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/npc25.jpeg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
              <img src={require("../../img/gallerybigptcs/npc25.jpeg")} alt=""/>
              <span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
              <span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
            </a>
          </div>
        </li>

        <li className='hexagon-wrapper'>
          <div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/g3.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
              <img src={require("../../img/thumbnails/g3.jpg")} alt=""/>
              <span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
              <span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
            </a>
          </div>
        </li>

        <li className='hexagon-wrapper'>
          <div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/g2.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
              <img src={require("../../img/thumbnails/g2.jpg")} alt=""/>
              <span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
              <span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
            </a>
          </div>
        </li>

        <li className='hexagon-wrapper'>
          <div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/g7.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
              <img src={require("../../img/thumbnails/g7.jpg")} alt=""/>
              <span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
              <span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
            </a>
          </div>
        </li>

        <li className='hexagon-wrapper'>
          <div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/thumbnails/g8.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
              <img src={require("../../img/thumbnails/g8.jpg")} alt=""/>
              <span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
              <span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
            </a>
          </div>
        </li>

        <li className='hexagon-wrapper'>
          <div className="cell">
            <a href={console.log('suppress')}onClick={()=>{
              this.props.dispatch(galleryPickedImageAction( require("../../img/gallerybigptcs/g10.jpg")))
              this.props.dispatch(openBigPictureAction( true ))
            }}>
              <img src={require("../../img/thumbnails/g10.jpg")} alt=""/>
              <span className="hexagallerytitle">{ engSrbParam ==='eng' ? 'Click' : 'Klikni' }</span>
              <span className="text">{ engSrbParam === 'eng' ? 'To zoom picture' : 'Uvecaj' }</span>
            </a>
          </div>
        </li>


     	</ul>
     </div>
   );
 }

}

const mapStateToProps = (state) => {
  const showGalleryImagesParam = state.showGalleryImagesParam;
  const engSrbParam = state.engSrbParam;

  return {
    showGalleryImagesParam,
    engSrbParam
  }
}
export default connect(mapStateToProps)(GalleryHexagonSection);
