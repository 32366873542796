import {
TEST_ACTION,
ABOUTBIGTEXTYOFFSET_ACTION,
SHOWABOUTBIGTEXT_ACTION,
ABOUTTHREECONTAINERSYOFFSET_ACTION,
SHOWABOUTTHREECONTAINERS_ACTION,
GALLERYHEADLINEYOFFSET_ACTION,
SHOWAGALLERYHEADLINE_ACTION,
ASYNCMARGINTOP_ACTION,
GALLERYPICKEDIMAGE_ACTION,
SHOWGALLERYIMAGES_ACTION,
ABOUTTEAMYOFFSET_ACTION,
SHOWABOUTTEAM_ACTION,
VIDEOGALLERYYOFFSET_ACTION,
SHOWVIDEOGALLERY_ACTION,
CONTACTYOFFSET_ACTION,
OPENBIGPICTURE_ACTION,
ENGSRB_ACTION
} from '../constants';

export function testAction ( testParam ) {
  return {
    type: TEST_ACTION,
    testParam
  }
}
export function aboutBigTextYoffsetAction ( aboutBigTextYoffsetParam ) {
  return {
    type: ABOUTBIGTEXTYOFFSET_ACTION,
    aboutBigTextYoffsetParam
  }
}
export function showAboutBigTextAction ( showAboutBigTextParam ) {
  return {
    type: SHOWABOUTBIGTEXT_ACTION,
    showAboutBigTextParam
  }
}

export function aboutThreeContainersYoffsetAction ( aboutThreeContainersYoffsetParam ) {
  return {
    type: ABOUTTHREECONTAINERSYOFFSET_ACTION,
    aboutThreeContainersYoffsetParam
  }
}
export function showAboutThreeContainersAction ( showAboutThreeContainersParam ) {
  return {
    type: SHOWABOUTTHREECONTAINERS_ACTION,
    showAboutThreeContainersParam
  }
}

export function galleryHeadlineYoffsetAction ( galleryHeadlineYoffsetParam ) {
  return {
    type: GALLERYHEADLINEYOFFSET_ACTION,
    galleryHeadlineYoffsetParam
  }
}
export function showGalleryHeadlineAction ( showGalleryHeadlineParam ) {
  return {
    type: SHOWAGALLERYHEADLINE_ACTION,
    showGalleryHeadlineParam
  }
}
export function asyncMarginTopAction ( asyncMarginTopParam ) {
  return {
    type: ASYNCMARGINTOP_ACTION,
    asyncMarginTopParam
  }
}
export function galleryPickedImageAction ( galleryPickedImageParam ) {
  return {
    type: GALLERYPICKEDIMAGE_ACTION,
    galleryPickedImageParam
  }
}
export function showGalleryImagesAction ( showGalleryImagesParam ) {
  return {
    type: SHOWGALLERYIMAGES_ACTION,
    showGalleryImagesParam
  }
}
export function aboutTeamYoffsetAction ( aboutTeamYoffsetParam ) {
  return {
    type: ABOUTTEAMYOFFSET_ACTION,
    aboutTeamYoffsetParam
  }
}
export function showAboutTeamAction ( showAboutTeamParam ) {
  return {
    type: SHOWABOUTTEAM_ACTION,
    showAboutTeamParam
  }
}
export function videoGalleryYoffsetAction ( videoGalleryYoffsetParam ) {
  return {
    type: VIDEOGALLERYYOFFSET_ACTION,
    videoGalleryYoffsetParam
  }
}
export function showVideoGalleryAction ( showVideoGalleryParam ) {
  return {
    type: SHOWVIDEOGALLERY_ACTION,
    showVideoGalleryParam
  }
}
export function contactYoffsetAction ( contactYoffsetParam ) {
  return {
    type: CONTACTYOFFSET_ACTION,
    contactYoffsetParam
  }
}
export function openBigPictureAction ( openBigPictureParam ) {
  return {
    type: OPENBIGPICTURE_ACTION,
    openBigPictureParam
  }
}
export function engSrbAction ( engSrbParam ) {
  return {
    type: ENGSRB_ACTION,
    engSrbParam
  }
}
